import React, { Component } from 'react';
import axios, { base } from '../axios-rsk';
import { Link } from 'react-router-dom';

export default class SimplePage extends Component {

    state = {
        thisPage: [],
        loadingThisPage: true
    }

    componentDidMount = async() => {
        const { match: { params } } = this.props;
        const { data: Page } = await axios.get(`/page/${params.url}/`)
        this.setState({ thisPage: Page, loadingThisPage: false });
    }
    

    render () {
        
        const page = this.state.thisPage === undefined ? null : this.state.thisPage
        let el = this.state.loadingThisPage === true ? 
        <div style={{ height: "75vh" }} className="conatiner-fluid p-0 d-flex align-items-center justify-content-center"><h1>Loading...</h1></div>
        :
        <div className=" simple-page-container">
            <h3 className="text-center ">{page.title}</h3>
            <p className="container simple-page-content" dangerouslySetInnerHTML={{__html: `${page.body}`}}></p>
        </div>
        return (el);
    }
    
}