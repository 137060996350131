{/*Primary Functional Area*/ }

import React from 'react';
import { useMediaPredicate } from "react-media-hook";
// import { Link } from 'react-router-dom';
import { HashLink as Link  } from 'react-router-hash-link';


const Section = props => {

    const scrollWithOffset = (el, offset) => {
        const elementPosition = el.offsetTop - offset;
        window.scroll({
          top: elementPosition,
          left: 0,
          behavior: "smooth"
        }); 
    }   
      
    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    const scrollToTargetAdjusted = (id) => {
        var element = document.getElementById(`${id}`);
        var headerOffset = 45;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;
    
        window.scrollTo({
             top: offsetPosition,
             behavior: "smooth"
        });
    }

    let element = props.align === "left" ?
        <div className="primary-functional-area-container">
            <div className="primary-functional-area-container-column-left">
                <img className="primary-functional-area-container-front-image" src={props.frontImg} alt="" />
                <ul className="primary-functional-area-container-list">
                    <li style={{ backgroundColor:props.color }} className="primary-functional-area-container-list-heading">Key Points</li>
                    <Link to={props.comp_1_url}>
                        <li className="primary-functional-area-container-list-point">{props.comp_1_title}</li>
                    </Link>
                    <Link to={props.comp_2_url}>
                        <li className="primary-functional-area-container-list-point">{props.comp_2_title}</li>
                    </Link>
                    <Link to={props.comp_3_url}
                        // scroll={el => { el.scrollIntoView(true); window.scrollBy(0, 0) }}
                        // scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center', offsetTop:"-40" })}
                        // scroll={el => scrollWithOffset(el, 0)} 
                        //    scroll={el => scrollToTargetAdjusted('rural-sports')}
                    >
                        <li className="primary-functional-area-container-list-point">{props.comp_3_title}</li>
                    </Link>
                </ul>
            </div>
            <div className="primary-functional-area-container-column-right">
                <h1 className="primary-functional-area-container-heading">{props.heading}</h1>
                <h2 className="primary-functional-area-container-subheading">{props.subheading}</h2>
                <p className="primary-functional-area-container-description" dangerouslySetInnerHTML={{ __html: `${props.description}` }}></p>
                <img className="primary-functional-area-container-mid-image" src={props.midImg} alt="" />
            </div>
        </div> :
        <div className="primary-functional-area-container-left">
            <div className="primary-functional-area-container-left-column-right">
                <h1 className="primary-functional-area-container-left-heading">{props.heading}</h1>
                <h2 className="primary-functional-area-container-left-subheading">{props.subheading}</h2>
                <p className="primary-functional-area-container-left-description" dangerouslySetInnerHTML={{ __html: `${props.description}` }}></p>
                <img className="primary-functional-area-container-left-mid-image" src={props.midImg} alt="" />
            </div>
            <div className="primary-functional-area-container-left-column-left">
                <img className="primary-functional-area-container-left-front-image" src={props.frontImg} alt="" />
                <ul className="primary-functional-area-container-left-list">
                    <li style={{ backgroundColor:props.color }} className="primary-functional-area-container-left-list-heading">Key Points</li>
                    <Link to={props.comp_1_url} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -23) }}>
                        <li className="primary-functional-area-container-left-list-point">{props.comp_1_title}</li>
                    </Link>
                    <Link to={props.comp_2_url} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -23) }}>
                        <li className="primary-functional-area-container-left-list-point">{props.comp_2_title}</li>
                    </Link>
                    <Link to={props.comp_3_url} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -23) }}>
                        <li className="primary-functional-area-container-left-list-point">{props.comp_3_title}</li>
                    </Link>
                </ul>
            </div>
        </div>
    return (
        <div>
            {element}
        </div>
    );
}

export default Section;