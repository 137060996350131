import React from 'react';
import { base } from '../axios-rsk'
import { Link } from 'react-router-dom'
const TeamMember = props => {
    return (
        <a style={{ color:"#383838" }} target="_blank" href={props.profile_url}>
        <div style={{borderRadius:props.borderRadius}} className="team_card-wrapper">
            <div style={{borderRadius:props.borderRadius}} className="team_card p-2">
            <img style={{borderTopLeftRadius:props.borderRadius, borderTopRightRadius:props.borderRadius}} className="img img-fluid" src={base + props.image} />
            <div className="team_card_body">
                <h5 className="team_card_title text-center">{props.name}</h5>
                <hr></hr>
                <h4 className="text-center team_card_title">{props.info}</h4>
                {/* <h4 className="text-center team_card_title">{props.designation}</h4> */}
                {/* <a style={{color:props.linkColor}} href={props.profile_url} className="team_card_button ">{props.linkName}<i className="pl-2 link fa fa-external-link"></i></a> */}
            </div>
            </div>
        </div>
        </a>
    )
}

export default TeamMember;