import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import fb from '../../assets/facebook.svg'
import insta from '../../assets/instagram.svg'
import tweet from '../../assets/twitter.svg'
import linkedin from '../../assets/linkedin.svg'

import axios, {base} from '../../axios-rsk';
import { setSaturation } from 'polished';

const Footer = props => {

    const [ email, setEmail ] = useState("");
    const [data, setDataIsValid] = useState(true);
    const [emailSentResp, setEmailSentResp] = useState(false);
    const [social, setSocial] = useState([])
    const re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const invalidEmail = email => {
       return email.split(',').map( email => email.trim() ).filter(email => re.test(email) === false);
    }

    useEffect(async() => {
        const { data : socialMedia } = await axios.get('/social-media/')
        setSocial(socialMedia)
    }, [])
    
    const handleSubmit = async e => {
        e.preventDefault();

        if (invalidEmail(email).length || email.length === 0) return setDataIsValid(false);

        const payload = {
            email : email
        }
        const { data :res } = await axios.post('/subscriber/', payload)
        console.log(res);
        if (res.email.length != 0) setEmailSentResp(true)

    }
    const handleChange = e => {
        setEmail(e.target.value);
        setDataIsValid(true)
    }

    return (
        <footer className="footer">
            <div className="container-row">
                <div className="row m-0 footer_top_row">
                    <div className="col-md-1"></div>
                    <div className="col-md-2 d-flex justify-content-center social_media_links_container">
                        <div className="row d-flex  justify-content-center social_media_links">
                            {social.map(m => 
                               <div className="col p-0">
                                    <a target="_blank" href={m.url}>
                                        <img className="p-3 img img-fluid" src={base + m.logo} alt=""/>
                                    </a>
                                </div> 
                                )}
                            
                            {/* <div className="col p-0">
                                <img className="p-3" src={insta} alt=""/>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="row m-0">
                            <div className="col-md-3">
                                {/* <h2>Newsletter</h2> */}
                            </div>
                            <div className="col-md-9 form_">
                                {/*Subscriber form added here */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0 footer_bottom_row">
                    <div className="col-md-1"></div>
                <div className="col-md-6 mt-5">
                    <div className="row footer_bottom_links d-flex justify-content-between flex-row">
                        <div style={{ color:"#f1f1f1" }}  className="col">
                            <h2>Office :</h2>
                            <p>Ranjit Singh Rozi Shiksha Kendra, 
                                 <br/>
                                Block A, Air Strip, IIT Kanpur, UP, 208016
                            </p>
                            <a style={{ color:"#f1f1f1" }} href="mailto:rsk@iitk.ac.in">
                                {/* <p><i className="fa fa-envelope mr-2"></i>rsk@iitk.ac.in</p> */}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 position-relative ">
                    <div className="row footer_links_section d-flex flex-row">
                        <ul className="col">
                            <li><Link to="/story"><span>Success Stories</span></Link></li>
                            <li><Link to="/activity"><span>Activities</span></Link></li>
                            <li><Link to="/all-media"><span>Media</span></Link></li>
                        </ul>
                        <ul className="col">
                            <li><Link to="/team"><span>Our Team</span></Link></li>
                            <li><Link to="/gallery"><span>Gallery</span></Link></li>
                            <li><Link to="/partner"><span>Our Partners</span></Link></li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
            <hr style={{ padding : "0.02rem", backgroundColor : "grey", width:"70%" }}/>
                <div className="footer-last-line" style={{paddingTop : "20px", paddingBottom : "20px"}}>
                    <h5 className="text-center mb-0">This site is owned by Ranjit Singh Rozi Shiksha Kendra (RSK), IIT Kanpur.</h5>
                    <h5 className="text-center mb-0">This site is best viewed at 1920 x 1080 resolution in Firefox 86+, Chrome 89+ or Safari 14+</h5>
                    <h5 className="text-center pb-4 mb-0" style={{ paddingTop : "5px" }}>Developed by <a target="_blank" style={{ textDecoration : 'none', color : '#c6c6c6' }} href="https://therajababu.com/" ><u>Raja Babu (Aspirebase Technologies Pvt Ltd.)</u></a></h5>
                </div>
        </footer>
    );
}

export default Footer;


// <form className="newsletter_form" onSubmit={handleSubmit}>
//                                     <div className="page_dot">
//                                     <svg className="page_dot_" width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                         <g filter="url(#filter0_d)">
//                                         <circle cx="19" cy="18" r="10" fill="white"/>
//                                         </g>
//                                         <defs>
//                                         <filter id="filter0_d" x="0" y="0" width="34" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
//                                         <feFlood flood-opacity="0" result="BackgroundImageFix"/>
//                                         <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
//                                         <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow"/>
//                                         <feOffset dx="-2" dy="-1"/>
//                                         <feGaussianBlur stdDeviation="2.5"/>
//                                         <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
//                                         <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
//                                         <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
//                                         </filter>
//                                         </defs>
//                                     </svg>
//                                     </div>
//                                     <div className="paper_clip">
//                                         <svg className="paper_clip_" width="20" height="52" viewBox="0 0 20 52" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                             <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7704 3.54132C17.6789 1.36002 14.7373 0 11.4841 0C5.15181 0 0.000509153 5.15232 0 11.4838L0 13.1066L0 21.6183L0 41.5978C0 46.8343 4.25988 51.0945 9.49641 51.0945C14.3347 51.0945 18.3324 47.4554 18.9139 42.7721C18.9657 42.6529 18.9945 42.5223 18.9945 42.3833V41.5978V25.0306V16.1841C18.9945 12.3926 15.9093 9.3069 12.1173 9.3069C8.32536 9.3069 5.2401 12.3926 5.2401 16.1841V38.2559C5.2401 38.7982 5.67939 39.2382 6.22235 39.2382C6.76552 39.2382 7.20492 38.7982 7.20492 38.2559V16.184C7.20492 13.4752 9.40761 11.2717 12.1173 11.2717C14.8258 11.2717 17.0294 13.4751 17.0294 16.184V25.0305V41.5977C17.0294 45.752 13.6509 49.1305 9.49692 49.1305C5.34417 49.1305 1.96543 45.7507 1.96543 41.5977L1.96543 21.6189L1.96543 13.1072L1.96543 11.4844C1.96543 6.23569 6.23529 1.96523 11.4841 1.96523C13.4191 1.96523 15.221 2.54554 16.725 3.54132H19.7704Z" 
//                                             fill="#3692C6"/>
//                                         </svg>
//                                     </div>
//                                 <div className="row m-0">
//                                     <h1>Subscribe to know more about our activities</h1>
//                                 </div>
//                                     <div className="row m-0">
//                                         <div className="col-md-6 p-0">
//                                             <div className="form-group d-flex">
//                                                 {/* <label htmlFor=""><h2>RSK Newsletter</h2></label> */}
//                                                 <input value={email} onChange={handleChange} placeholder="Email address" className="form-control" type="text"/>
//                                                 <button className="ml-2">Submit</button>
//                                             </div>
//                                             {!data && <p style={{ fontSize:"10px", color:"#E46D65" }}>invalid email!</p>}
//                                             {emailSentResp && <p style={{ fontSize:"10px", color:"#149A32" }}>Email registered!</p>}
//                                         </div>
//                                         <div className="col-md-6">
                                            
//                                         </div>
//                                     </div>
//                                 </form>
