{/*Primary Functional Area*/}

import React from 'react';
import { useMediaPredicate } from "react-media-hook";

const Section = props => {
    
    {/*Initial class of elements*/}
    let person_standing_image_class = "img-stack-1";
    let main_heading = "heading-img-main";
    let mid_image_class = "img-stack-2";
    let description = "describe-content";
    let heading_at_bottom = "heading-img-bottom";
    let subheading = "subheading";

    {/*classes if the align is right*/}
    if (props.align == "right") {
        person_standing_image_class = "img-stack-1__align_right";
        mid_image_class = "img-stack-2__align_right";
        main_heading = "heading-img-main__align_right";
        description = "describe-content__align_right";
        heading_at_bottom = "heading_img_bottom__align_right";
        subheading = "subheading__align_right";
    }
    {/*Background circle (only for laptop view)*/}
    const backCIrcle = (color, align) => {
        const biggerThan400 = useMediaPredicate("(min-width: 1100px)");
        let margin="10rem 0 0 3rem"
        if (align === "left") {
             margin = "6rem 0 0 6rem"
        }
        else if (align === "right"){
            margin="16rem 0 0 0"
        }
            return (
                <div>
                    {biggerThan400 && <div style={{height:"110vh", width:"110vh", backgroundColor:color, borderRadius:"50%", position:"absolute", zIndex:"-1", margin:margin, opacity:0.1}} className="back_circle_"></div>}
                </div>
            )
        
    };
    return (
        <div className="container-11 p-0">
                {backCIrcle(props.color, props.align)}
                <div className="img-stack ">
                    <div className={person_standing_image_class}>
                        <img className="im1" src={props.frontImg} alt=""/>
                        <ul className="primary_area_imp_links">
                            <span style={{backgroundColor:props.color}} className="links_heading">Highlights</span>
                            <li><a style={{color:"#2a85ad"}} href={props.u1}>{props.t1}</a></li>
                            <li><a style={{color:"#2a85ad"}} href={props.u2}>{props.t2}</a></li>
                            <li><a style={{color:"#2a85ad"}} href={props.u3}>{props.t3}</a></li>
                        </ul>
                    </div>
                    <div className="group-1">
                        <div className={main_heading}>
                            <div style={{borderBottom:`8px solid ${props.color}`}}><h1 className="">{props.heading}</h1> <h3 className={subheading}>{props.subheading}</h3></div> 
                        </div>
                        <div className={mid_image_class}>
                            <img className="im2" src={props.midImg} alt=""/>
                        </div>
                        <h1 className={heading_at_bottom}>WE BELIEVE</h1>
                        <div className={description}>
                        <p style={{color:`${props.color}`}} className="description" dangerouslySetInnerHTML={{__html: `${props.description}`}}></p>
                        <ul className="primary_area_imp_links_for_mobile_view">
                            <span style={{backgroundColor:props.color}} className="links_heading_for_mobile_view">Highlights</span>
                            <li>{props.t1}</li>
                            <li>{props.t2}</li>
                            <li>{props.t3}</li>
                        </ul>
                        </div>
                    </div>
                    <div className="img-stack-3">
                    <img className="im3" src={props.backImg} alt=""/>
                    </div>    
                </div>   
            </div>



    );
}

export default Section;