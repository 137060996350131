import React, { Component } from 'react';
import axios, { base } from '../../axios-rsk';
import { Link } from 'react-router-dom'

class Documents extends Component {

    state = {
        document : [],
        loadingDocument : true
    }

    componentDidMount = async() => {
        const { data : document } = await axios.get('/document/')
        this.setState({ document, loadingDocument : false })
    }
    

    render() {
        const el = this.state.loadingDocument === true ?
        <div style={{height:"60vh"}} className="container d-flex justify-content-center align-items-center pt-5"><h1 className="pt-5">Loading ...</h1></div>
        :
        <table className="notifications_table table table-hover">
            <thead>
                <tr className="table-active">
                    <th>Date</th>
                    <th>Title</th>
                </tr>
            </thead>
            <tbody>
            {this.state.document.map(m => 
                // <Link style={{color:"#1D8EB1"}} to={'/notification/'+m.slug}>
                    <tr className=''>
                        <td>
                            <h6 className="created_at">{m.created_at.split('T')[0].split('-').reverse().join('/')}</h6> 
                        </td>
                        <td>
                            <h5 className="mb-0 notification_title">
                            <a style={{ color:"#222" }} target="_blank" href={base + m.link}>{m.name}</a>
                            </h5>
                        </td>
                    </tr> 
                // </Link>   
            )}
            </tbody>
        </table>
        return (
            <div className="container-fluid all_notifications_wrapper p-0">
                <div className="all_notifications_container">
                    <h1 className="pt-5">Documents</h1>
                    {el}
                    {/* <Link style={{ color:"#444", fontSize:"15px" }} className="border rounded p-2" to="/">Home</Link> */}
                </div>
            </div>
        );
    }
}

export default Documents;