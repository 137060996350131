import React, { Component } from 'react'
// import './dropdown.scss';
// import { Link } from 'react-router-dom';
import { HashLink as Link  } from 'react-router-hash-link'; 

import $ from 'jquery';
export default class Dropdown extends Component {
    state = {
        dropdown: false,
        arrow: "fa-caret-down",
    }
    ref = React.createRef();

    toggleDropdown = () => {
        this.setState({ dropdown: !this.state.dropdown });
        this.setState({ arrow: "fa-caret-up" })
    }
    handleClickOutside = e => {
        if (this.ref.current && !this.ref.current.contains(e.target)) {
            this.setState({ dropdown: false });
            this.setState({ arrow: "fa-caret-down" })
        }
    };
    handleDropDownClose = () => {
        this.setState({ dropdown: false, arrow: "fa-caret-down" });
    }
    handleDropDownClose2 = () => {
        document.querySelector('#agriculture').scrollTo(0, 0);
    }
    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutside, true);

    }
    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClickOutside, true);
    }
    myRef = React.createRef();
    // https://stackoverflow.com/questions/39615074/link-by-id-in-react-js
    //to change scroll positions in react and link by id in same page
    executeScroll = () => myRef.current.scrollIntoView()

    handleScroll(id){
        console.log(1)
        $('html, body').animate({
            scrollTop: $( '' + id ).offset().top -100
        }, 500);        
    }

    render() {
        const dropdown = this.state.dropdown;
        const arrow = this.state.arrow;
        return (
            <div id="dd" className="dropdown ml-3 mr-3">
                <span onClick={this.toggleDropdown} className="title">{this.props.title}<i className={"fa " + arrow} aria-hidden="true"></i></span>
                {dropdown && <div ref={this.ref} className="dropdown-content content ">
                    <ul className="list-group">
                        {this.props.title != "what" ?
                            this.props.items === undefined ? null : this.props.items.map(p =>
                                <Link
                                    onClick={this.handleDropDownClose}
                                    style={{ textDecoration: "none", color: "black" }}
                                    to={'/' + this.props.slugPrefix + p.slug + '/'}>
                                    <li className={this.props.classes}>
                                        <span style={{ color: "black" }}>{p.title}</span>
                                    </li>
                                </Link>
                            ) :
                            this.props.items === undefined ? null : this.props.items.map(p =>
                                <a
                                    // onClick={this.handleDropDownClose2}
                                    onClick={this.handleScroll.bind(this, p.slug)}
                                    style={{ textDecoration: "none", color: "black" }}
                                    
                                    href={'/' + p.slug + '/'}>
                                    <li className={this.props.classes}>
                                        <span style={{ color: "black" }}>{p.title}</span>
                                    </li>
                                </a>
                            )
                        }

                    </ul>
                </div>}
            </div>
        );
    }
}

Dropdown;