import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import axios, { base } from '../../axios-rsk'

class BlogPage extends Component {

    state = {
        thisBlog : [],
        loadingBlog : true
    }

    componentDidMount = async() => {
        const { match: { params } } = this.props;
        if (params === null)this.setState({ urlNull:true })
        const { data: thisBlog } = await axios.get(`/blogs/${params.url}/`)
        this.setState({ thisBlog, loadingBlog:false });
    }
    

    render() {

        const thisBlog = this.state.thisBlog == undefined ? null : this.state.thisBlog
        const el = this.state.loadingBlog === true ? <div style={{ height:"66vh" }} className="d-flex justify-content-center align-items-center"><h1>Loading...</h1></div> 
        :
        <div className="container-fluid each-blog-page">
                <div className="container each-blog-wrapper">
                    <h2 className="each-blog-heading">{thisBlog.title}</h2>
                    <h5 className="each-blog-date">{thisBlog.published_on}</h5>
                    <div className="row m-0 d-flex justify-content-center">
                        <img src={base + thisBlog.image} className="img img-fluid each-blog-banner-image" alt=""/>
                    </div>
                    <p className="each-blog-content" dangerouslySetInnerHTML={{ __html: `${thisBlog.body}` }}></p>
                </div>
            </div>
        return (el);
    }
}

export default BlogPage;