import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Scrollreveal from 'scrollreveal';
import axios, { base } from '../../axios-rsk';

const StoryCard = props => {

    useEffect(() => {
        // Scrollreveal().reveal('.story_Page_card_template',  {origin:'bottom', distance: '130px', viewFactor:0.5, } )
    }, [])
    return (
        <div className="col-md-6 p-0">
            
            <div className="story_Page_card_template">
                <img src={props.image} className="img img-fluid story_page_card_image"/>
                <h2 className="mb-0"><span>{props.title}</span></h2>
                <p className="mb-0">{props.info}</p>
                <Link to={'/story/'+props.slug}><h6 className="text-center mb-0"><span style={{padding:"0.5rem 1rem"}}>Read more ›</span></h6></Link>
            </div>
            
        </div>
    );
}

export default StoryCard