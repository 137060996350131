import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import "./sass/main.scss";

import Navbar from './components/navbar/navbar';
import Footer from "./components/footer/footer";
import Homepage from "./views/Homepage";

{/*Activity page imports*/}
import ActivityPageContainer from "./components/activity/ActivityPageContainer";
import EachItemActivityPage from "./components/activity/EachItemActivityPage";

{/*About page imports*/}
import About from "./components/about/about";

// import Donate from './components/Donate/donate';
// import Donate_jon_btn from './components/DonateJoinBtn/donate_jon_btn';

{/*Partner page imports*/}
import Partner from "./components/partner/partner";
import PartnerPage from './containers/Partner';
import PartnerPageNewTemplate from './common/partnerPageNewTemplate';
import TeamPageContainer from "./containers/TeamPageContainer";

import News from './components/News/News'

{/*Notification page imports*/}
import Notification from "./components/Notification/Notification";
import AllNotification from "./containers/AllNotificationContainer";

{/*Template page imports*/}
import TemplatePage from "./views/TemplatePage";
import TemplatePage2 from "./views/TemplatePage2";
import PillarPage from './views/PillarPage';
import TestimonialPage from "./containers/testimonialPage";

import NotFoundPage from "./views/404";
import VillageCardsContainer from "./components/village/VillageCardsContainer";
import EachVillage from "./components/village/EachVillage";
import Gallery from "./components/gallery/Gallery";
import AllSuccessStoriesContainer from "./components/success-stories/AllSuccessStoriesContainer";
import EachSuccessStory from "./components/success-stories/EachSuccessStory";

//This component helps in scrolling to top, when a new page loads
import ScrollToTop from './common/scrollToTop';
import Join from "./components/Join/Join";
import Contact from "./components/contact";
import SimplePage from './common/templateSimplePage';
import Donate_jon_btn from './components/DonateJoinBtn/donate_jon_btn'
import BlogPage from "./components/blog/blogPage";
import BlogContainer from "./containers/blogContainer";
import Documents from "./components/documents/documents";


function App() {
  return (
    <div style={{ padding: "0", position: "relative"}}>
      <Donate_jon_btn />
      <Navbar />
      
      <ScrollToTop>
      <Switch>
        <Route exact path="/" component={Homepage} />
        
        {/*Activity page routes*/}
        <Route path='/activity/:url' render={(props) => <EachItemActivityPage  {...props} key={props.location.key} />} />
        <Route path='/activity' component={ActivityPageContainer} />

        {/* <Route path='/contact' component={Contact} /> */}

        {/*Gallery page routes*/}
        <Route path='/gallery' component={Gallery} />

        {/*Success stories page routes*/}
        <Route path='/story/:url' render={(props) => <EachSuccessStory  {...props} key={props.location.key} />} />
        <Route path='/story' component={AllSuccessStoriesContainer} />
        
        {/*News page routes*/}
        <Route path='/all-media' component={News} />

        {/*About page route*/}
        
        
        {/*Partner page routes*/}
        <Route path='/partner/:url' render={(props) => <PartnerPageNewTemplate  {...props} key={props.location.key} />} />
        <Route path='/partner' component={PartnerPage} />

        {/*Team page routes*/}
        <Route path='/team' component={TeamPageContainer} />

        {/*Blogs page routes*/}
        <Route path='/blogs/:url' render={(props) => <BlogPage  {...props} key={props.location.key} />} />
        <Route path='/blogs' component={BlogContainer} />

        <Route path='/document' component={Documents} />

        {/*Village page routes*/}
        <Route path='/village/:url' render={(props) => <EachVillage  {...props} key={props.location.key} />} />
        <Route path='/village' component={VillageCardsContainer} />

        {/*Notifications routes*/}
        <Route path='/news/:url' render={(props) => <Notification  {...props} key={props.location.key} />} />
        <Route path='/news' component={AllNotification} />
        
        {/*Template pages*/}
        <Route path="/template" component={TemplatePage}/>
        <Route path="/template2" component={TemplatePage2}/>

        {/* <Route path="/donate" component={Donate}/>
        <Route path="/join" component={Join}/> */}

        {/* <Route path='/testimonial' component={TestimonialPage} /> */}
        <Route path='/pillar/:pillarname' render={(props) => <PillarPage {...props} key={props.location.key}/>} />
        <Route path='/page/:url' render={(props) => <SimplePage  {...props} key={props.location.key} />} />
        <Route path='/:url' render={(props) => <SimplePage  {...props} key={props.location.key} />} />
        
        
        {/*404 page route*/}
        {/* <Route path='/:random' component={NotFoundPage} /> */}

        
      </Switch>
      </ScrollToTop>

      <Footer />
    </div>
  );
}

export default App;
