import React, { useState, useEffect } from 'react';
import { base } from '../../axios-rsk'; 
import ScrollReveal from 'scrollreveal';
import { Link } from 'react-router-dom';
const Card = (props) => {
    function hexToRgbA(hex){
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.3';
        }
        throw new Error('Bad Hex');
    }

    let colordisp = hexToRgbA(props.color);

    useEffect(() => {
        ScrollReveal().reveal(".activity-card", {origin:'bottom', distance: '130px', viewFactor:0.5 } )
    }, [])
    return (

        <Link to={'/activity/'+props.slug}>
            <div className="activity-card">
                <div className="overlay d-flex justify-content-center align-items-center iitk-logo " style={{backgroundColor: colordisp}}>
                    <img className="img img-fluid iitk-logo-card" src={base+props.logo} alt="" />
                </div>
                <img src={base+props.imageURL} className="card-image_"></img>
                <h1 className="card-title_">{props.title}</h1>
            </div>
        </Link>

    )
}

export default Card;


{/* Blob */}
{/* <svg className="blob" viewBox="0 0 683 661" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" 
                    clip-rule="evenodd" 
                    d="M414.347 98.1729C457.854 120.196 471.471 172.745 502.099 
                    210.678C541.388 259.338 626.636 289.541 618.944 351.564C611.232 
                    413.754 525.641 429.218 471.155 460.207C433.626 481.551 394.287 
                    493.015 352.321 503.205C308.266 513.902 265.504 528.776 220.829 
                    520.988C165.263 511.301 100.43 501.431 69.9557 453.982C39.309 
                    406.264 53.4163 341.894 71.26 288.103C86.7615 241.373 127.024 
                    210.807 162.09 176.24C195.143 143.656 224.97 106.578 269.328 
                    92.8509C316.683 78.1956 370.1 75.7754 414.347 98.1729Z" 
                    fill={props.color} />
                </svg> */}