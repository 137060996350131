import React, { Component } from 'react';
import axios, {base} from '../axios-rsk';
// import { base } from '../axios-rsk';
import { rgba } from 'polished';
class TestimonialPage extends Component {
    state = {
        testimonials : [],
        index:0,
        ImageId:"img0",
        testimonialContentDisplay : "none",
        JoiningLineWidth:""
    }
    componentDidMount = async() => {
        const {data:testimonials} = await axios.get('/home/testimonial/') 
        this.setState({ testimonials })
    }

    toggleTestimonialContent = (k) => {
        this.setState({index:k})
        // this.setState({ImageId:"img"+k})
        const distance_of_line = document.getElementById("contentDiv").getBoundingClientRect().left - document.getElementById("img" + k).getBoundingClientRect().left-113
        this.setState({JoiningLineWidth : distance_of_line })
        this.setState({testimonialContentDisplay:"block"})
        console.log("printing the distance of the line")
        console.log(document.getElementById("contentDiv").getBoundingClientRect().left);
        console.log(document.getElementById("img" + k).getBoundingClientRect().left);
        console.log(distance_of_line)

    }
    hexToRgbA(hex, opacity){
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){   
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + opacity + ')';
        }
        throw new Error('Bad Hex');
    }
    render() {
        const i = this.state.index
        const name = this.state.testimonials[i] === undefined ? null : this.state.testimonials[i].name
        const message = this.state.testimonials[i] === undefined ? null : this.state.testimonials[i].message
        const designation = this.state.testimonials[i] === undefined ? null : this.state.testimonials[i].designation
        const testimonial_color = this.state.testimonials[i] === undefined ? null : this.hexToRgbA(this.state.testimonials[i].color, 1)
        const testimonial_color_ = this.state.testimonials[i] === undefined ? null : this.hexToRgbA(this.state.testimonials[i].color, 0.6)
        const testimonial_contents_styling = {
            backgroundColor:testimonial_color
        }
        
        return (
            <div className="container_testimonial_page">
                <h1>Testimonials</h1>
                <div style={{display:this.state.testimonialContentDisplay, }} className="container_testimonial_contents">
                    <div className="container_inner_contents">
                    <div style={{background:`linear-gradient(to right, ${testimonial_color}, ${testimonial_color_} )`}} id="contentDiv" className="content_name_and_message">
                        <h2>{name}</h2>
                        <h3>{designation}</h3>
                        <h4>{message}</h4>
                    </div>
                    <svg className="content_to_image_line" width={this.state.JoiningLineWidth} height="auto" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15.5" cy="260.5" r="12.5" stroke="url(#paint0_linear)" stroke-width="6"/>
                        <path d="M16 250V3H1000" stroke="url(#paint1_linear)" stroke-width="6"/>
                        <defs>
                        <linearGradient id="paint0_linear" x1="0" y1="180.5" x2="31" y2="180.5" gradientUnits="userSpaceOnUse">
                        <stop stop-color={testimonial_color}/>
                        {/* <stop offset="1" stop-color="#FFC837"/> */}
                        </linearGradient>
                        <linearGradient id="paint1_linear" x1="16" y1="85.5" x2="378" y2="85.5" gradientUnits="userSpaceOnUse">
                        <stop stop-color={testimonial_color}/>
                        {/* <stop offset="1" stop-color="#FFC837"/> */}
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                </div>
                <div className="container_testimonials_image">
                    <div className="container-fluid ">
                        <div className="row container_image_row">
                            {this.state.testimonials.map((m, k) => 
                                <div className="col p-0 img_container_testimonial" key={k}>
                                    <img id={"img"+k} onClick={() => this.toggleTestimonialContent(k)} src={base + m.image} alt="" className="pl-5 testimonial_image img img-fluid"/>
                                        <div className="blob_testimonial_image">
                                            <svg height="auto" viewBox="0 0 470 454" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path 
                                                fill-rule="evenodd"
                                                clip-rule="evenodd" 
                                                d="M224.753 0.364345C269.914 -3.99219 307.303 31.6536 344.685 57.38C383.154 83.8541 423.214 109.44 444.016 151.262C467.027 197.526 476.051 250.264 465.815 300.915C454.735 355.737 434.221 416.715 385.007 443.24C336.654 469.3 278.701 441.138 224.753 430.837C181.765 422.629 140.971 412.156 103.525 389.493C62.8077 364.85 8.85296 342.27 0.864228 295.332C-7.19043 248.006 43.1134 213.051 66.3468 171.047C84.011 139.113 95.6996 105.75 120.522 79.0041C150.874 46.2993 180.353 4.64741 224.753 0.364345Z"
                                                fill={m.color}/>
                                                <defs>
                                                <linearGradient id="paint0_linear" x1="0" y1="227" x2="470" y2="227" gradientUnits="userSpaceOnUse">
                                                <stop stop-color={m.color}/>
                                                </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                </div>    
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TestimonialPage;