{/* (/partner) page route card template */}

import React from 'react';
import { Link } from 'react-router-dom'

import { base } from '../axios-rsk'

const PartnerPageCardComp = props => {

    return (
        <div style={{borderRadius:props.borderRadius}} className="_card-wrapper">
            <Link to={'/' + props.slugPrefix + '/' + props.url + '/'}>
                <div style={{borderRadius:props.borderRadius}} className="_card">
                <img style={{borderTopLeftRadius:props.borderRadius, borderTopRightRadius:props.borderRadius}} className="border-bottom img img-fluid" src={base + props.logo} />
                <div className="_card_body">
                    <h5 className="_card_title text-center">{props.title}</h5>
                    {/* <Link style={{color:props.linkColor}} to={'/' + props.slugPrefix + '/' + props.url + '/'} className="_card_button ">{props.linkName}<i className="pl-2 link fa fa-external-link"></i></Link> */}
                </div>
                </div>
            </Link>
        </div>
    )
}

export default PartnerPageCardComp;