import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import axios, { base } from '../../axios-rsk'

//Story Card
import StoryCard from './StoryCard'

class AllSuccessStoriesContainer extends Component {
    state = {
        allStories : [],
        loadingAllStories : true
    }

    componentDidMount = async() => {
        const { data : allStories } = await axios.get('/story/')
        console.log(allStories)
        this.setState({ allStories, loadingAllStories : false })
    }
    
    render() {
        const allStories = this.state.allStories === undefined ? null : this.state.allStories
        const el = this.state.loadingAllStories == true ? 
        <div style={{ height:"70vh" }} className="container d-flex justify-content-center align-items-center">
            <h1>Loading...</h1>
        </div>
        :
        <div className="container story_card_container">
            <h5 className="p-4" ><Link style={{color:"#333"}} to='/'>HOME</Link> &nbsp;|&nbsp; STORIES</h5>
            <div className="row m-0">
                {allStories.map(m => 
                    <StoryCard title={m.title} info={m.info} image={base + m.image} created_at={m.created_at} slug={m.slug}/>
                    )}
            </div>
            </div>
        return (
            <div className="container-fluid p-0 success_stories_background">
                <div className=" success_stories_wrapper">
                    <div className="success_stories_banner">
                        <h1>Success Stories</h1>
                    </div>
                    {el}
                </div>
            </div>
        );
    }
}

export default AllSuccessStoriesContainer;