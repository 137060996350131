import React, { Component } from 'react';
import axios, { base } from '../../axios-rsk';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

class EachItemActivityPage extends Component {
    state = {
        thisActivity : [],
        loadThisActivity : false
    }
    componentDidMount = async() => {
        window.scrollTo(0, 0)
        const { match: { params } } = this.props;
        const {data:activity} = await axios.get(`/activity/${params.url}/`)
        this.setState({ thisActivity:activity, loadThisActivity : true });
    }
    hexToRgbA = (hex, opacity) => {
        let c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){   
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + opacity + ')';
        }
        throw new Error('Bad Hex');
    }
    render() {
        const thisActivity = this.state.thisActivity === undefined ? null : this.state.thisActivity
        let el = this.state.loadThisActivity === false ? 
        <div className="vh-100 d-flex justify-content-center align-items-center"><h2>Loading...</h2></div>
        :
            <div>
                <div className="each_Item_activity_page_banner container-fluid p-0">
                    <img src={base + thisActivity.thumbnail} alt=""/>
                    {/* <motion.div style={{backgroundColor:this.hexToRgbA(thisActivity.color, 0.3)}} className="banner_headings" initial={{x:-100}} animate={{x:0}} transition={{duration:0.5, ease: "easeOut"}}>
                        <h1>{thisActivity.title}</h1>
                    </motion.div> */}
                </div>
                
                <div className="container activity_page_content_container ">
                {/* <h4><Link to="/" style={{color:"#444"}}>Home</Link> &nbsp;>&nbsp;<Link to="/activity" style={{color:"#444"}}>Activity</Link> &nbsp;>&nbsp; {thisActivity.title}</h4> */}
                    <h4 className="text-center">{thisActivity.title}</h4>
                    <p className="activity_page_content" dangerouslySetInnerHTML={{__html: `${thisActivity.body}`}}></p>
                </div>
            </div>
        return (
            el
        );
    }
}

export default EachItemActivityPage;