import React, { Component } from 'react'
import axios from "../axios-rsk";
import Card from "../components/Card/HomePageActivityCard";
import Section from "../components/Section/PrimaryFA_section";
class PrimaryFunctionalSecton extends Component{
    
    state={
        primaryfunctiondata: []
    }
    componentDidMount(){
        axios.get('/home/primary-functional-area/')
        .then(response => {
            const primaryfunctiondatarecieved = [];
            for (let key in response.data) {
                primaryfunctiondatarecieved.push({
                    ...response.data[key]
                })
            }
            
            this.setState({primaryfunctiondata: primaryfunctiondatarecieved});
        })
        .catch(error => {
            console.log("Error occured fetching api")
            console.log(error);
        })
    }
    
    render(){   
        
        console.log(this.state.primaryfunctiondata);
        const el = <div>
            {this.state.primaryfunctiondata.map(section => {
                return(
                    <Section
                        heading={section.heading}
                        subheading={section.subheading}
                        color={section.heading}
                        backgroundURL={section.background}
                        foregroundURL={section.foreground}
                        description={section.description}
                    />
                )
            })}
        </div>
        return(
            <div className="container">
                <h1>Primary Functional Area</h1>
                {el}
            </div>
        )
    }
}
export default PrimaryFunctionalSecton;