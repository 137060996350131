import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios, { base } from '../axios-rsk';
import $ from 'jquery';

class PillarPage extends Component {
    state = {
        PillarData: [],
        loadingThisPage: true,
        currentPillar :  null
    }

    handleScroll(id){
        $('html, body').animate({
            scrollTop: $( '' + id ).offset().top -100
        }, 500);        
    }

    componentDidMount = async () => {
        const { match: { params } } = this.props;
        this.setState({currentPillar: params.pillarname});
        const { data: Page } = await axios.get(`/pillar/${params.pillarname}/`);
        this.setState({ PillarData: Page, loadingThisPage: false });
    }
    

    render() {
        const el = (this.state.loadingThisPage == true)? 
        <div style={{height:"80vh", width:"100%", alignItems:"center", justifyContent:"center"}}> 
            <h1 style={{margin:"auto", flexGrow:"1", width:"100%", textAlign:"center", marginTop:"30vh", fontSize:"3rem"}}>Loading ...</h1>
        </div> : 
        <div>
            {this.state.PillarData.map( m => {
                return(
                    <div className="pillar-page-item" id={m.slug}>
                        <h1 className="pillar-page-item-heading">
                            {m.title}
                        </h1>
                        <div className="d-flex justify-content-center">
                            <img src={base + m.image} className="pillar-page-item-image"></img>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: `${m.body}` }} className="pillar-page-item-body"></div>
                    </div>
                )
            })}
        </div>
        return (
            <div className="pillar-page">
                <h2 className="pillar-name" style={{ textTransform:"capitalize" }}>
                    {this.state.currentPillar === null ? [] : this.state.currentPillar.replace('-', ' ')}
                </h2>
                {el}
            </div>
        )
    }
}

export default PillarPage;