import React, {useState} from 'react'
import styled, {css} from 'styled-components';
import { Link } from 'react-router-dom';
const ActivityPageCard = props => {
    const [hover, setHover] = useState(false)
    const hexToRgbA = (hex, opacity) => {
        let c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){   
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + opacity + ')';
        }
        throw new Error('Bad Hex');
    }
    // const Wrapper = styled.div`
    //     &:hover {
    //         box-shadow: ${hexToRgbA(props.color, 0.2)} -4px 9px 25px -6px;
    //     }  
    // `;
    return (
        <Link to={'/activity/'+props.slug}>
            <div  onMouseEnter={()=>{ setHover(true) }} onMouseLeave={()=>{ setHover(false) }} className="activity_Page_card_template">
                <img src={props.thumbnail} className="img img-fluid activity_page_card_image"/>
                <h1 style={{color:props.color, hover: { backgroundColor:"black" }}}><img src={props.logo} className="activity_page_card_logo img img-fluid"/>{props.title}</h1>
            </div>
        </Link>
    );
}

export default ActivityPageCard