import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link  } from 'react-router-hash-link'; 

import axios, { base } from '../../axios-rsk';

class PfaMobile extends Component {

    render() {
        const align = this.props.align
        const textAlign = align === 'left' ? "left" : "right"
        return (
            <div  id={this.props.slug + '-mobile'} className="pfa-mobile">
                <h1 style={{ textAlign : textAlign }}>{this.props.heading}</h1>
                <h3 style={{ textAlign : textAlign }}>{this.props.subheading}</h3>
                <p style={{ textAlign : textAlign }} className={"pfa-mobile-content" + ` pfa-mobile-content-style-is-` + textAlign} dangerouslySetInnerHTML={{__html:`${this.props.description}`}} />
                <div className={"d-flex justify-content-" + `${align === 'left' ? "start" : "end"}`}>
                    <img src={this.props.midImg} className={"img img-fluid" } alt=""/>    
                </div>
                <h6 style={{ textAlign : textAlign, backgroundColor:this.props.color }} className={"pfa-mobile-highlight-heading" + ` pfa-mobile-highlight-heading-style-is-` + textAlign}>Key points</h6>
                <ul className="pfa-mobile-highlights">
                    <Link scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }} to={this.props.comp_1_url}><li style={{ textAlign : textAlign }}>{this.props.comp_1_title}</li></Link>
                    <Link scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }} to={this.props.comp_2_url}><li style={{ textAlign : textAlign }}>{this.props.comp_2_title}</li></Link>
                    <Link scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }} to={this.props.comp_3_url}><li style={{ textAlign : textAlign }}>{this.props.comp_3_title}</li></Link>
                </ul>
            </div>
        );
    }
}

export default PfaMobile;