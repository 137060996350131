import React, { Component } from 'react';
import axios, { base } from '../axios-rsk';
import TeamMember from '../common/teamMember';
class TeamPageContainer extends Component {
    state = {
        AdvisoryCommittee: [],
        Coordi:[],
        staff:[],
        isLoading: true,
        currentDesignation: "all"
    }
    componentDidMount = async () => {
        const { data: team } = await axios.get('/team/')
        let AdvisoryCommittee = [];
        let Coordi = [];
        let staff = [];
        for (let i=0 ;i <team.length ; i++) {
            team[i].designation.toUpperCase() === 'advisory-committee'.toUpperCase() ? AdvisoryCommittee.push(team[i]) : 
            team[i].designation.toUpperCase() === 'student-coordinator'.toUpperCase() ? Coordi.push(team[i]) : staff.push(team[i]);

        }
        this.setState({ Coordi })
        this.setState({ AdvisoryCommittee })
        this.setState({ staff })
        this.setState({ isLoading: false })
    }

    render() {
        let el = <div className="container d-flex justify-content-center vh-100 pt-5">
                    <h1 className="pt-5">Loading ...</h1>
                </div>
        if (this.state.isLoading === false) {
            el = <div>
                <div className="team_page_container mt-3 container">
                    <h1 className="text-center mt-5">Our Team</h1>


                    <h2 className="team_designation_heading text-center"><span>Advisory Committee</span></h2>
                    <div className="row d-flex justify-content-center m-0" style={{transition: "all 1s"}}>
                        {this.state.AdvisoryCommittee.map(m => {
                            return (
                                <div className="col-md-3 p-0">
                                    <TeamMember slugPrefix="team" info={m.info} name={m.name} designation={m.designation} linkName="Website" borderRadius="0.5rem" dettails={m.details} profile_url={m.profile_url} image={m.image} />
                                </div>
                            )
                        }
                        )}
                    </div>

                    {this.state.Coordi.length != 0 && <h2 className="team_designation_heading text-center"><span>Student Coordinator</span></h2>}
                    <div className="row d-flex justify-content-center m-0">
                        {this.state.Coordi.map(m => {
                            if (m.designation === this.state.currentDesignation || "all" === this.state.currentDesignation) {
                                return (
                                    <div className="col-md-3 p-0" style={{transition: "all 1s"}}>
                                        <TeamMember slugPrefix="team" info={m.info} name={m.name} designation={m.designation} linkName="Website" borderRadius="0.5rem" dettails={m.details} profile_url={m.profile_url} image={m.image} />
                                    </div>
                                )
                            }
                        }
                        )}
                    </div>

                    {this.state.staff.length !=0 && <h2 className="team_designation_heading text-center"><span>Staff</span></h2>}
                    <div className="row d-flex justify-content-center m-0">
                        {this.state.staff.map(m => {
                            if (m.designation === this.state.currentDesignation || "all" === this.state.currentDesignation) {
                                return (
                                    <div className="col-md-3 p-0" style={{transition: "all 1s"}}>
                                        <TeamMember slugPrefix="team" info={m.info} name={m.name} designation={m.designation} linkName="Website" borderRadius="0.5rem" dettails={m.details} profile_url={m.profile_url} image={m.image} />
                                    </div>
                                )
                            }
                        }
                        )}
                    </div>
                </div>
            </div>
        }
        return (el);
    }
}

export default TeamPageContainer;