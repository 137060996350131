import axios from 'axios';
const instance = axios.create({

    // Base Domain Name

    baseURL: 'https://rsk.iitk.ac.in/api/'
    //baseURL: 'http://127.0.0.1:8000/api/'

});

export let base = 'https://rsk.iitk.ac.in';
//export let base = 'http://127.0.0.1:8000';

export default instance;