import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import axios, {base} from '../axios-rsk';

class partnerPageNewTemplate extends Component {

    state = {
        thisPartner: [],
        urlNull:false,
        loadingPartner:true
    }

    componentDidMount = async () => {
        const { match: { params } } = this.props;
        if (params === null)this.setState({ urlNull:true })
        const { data: thisPartner } = await axios.get(`/partner/${params.url}/`)
        this.setState({ thisPartner, loadingPartner:false });
        console.log(params)
    }


    render() {
        if (this.state.urlNull === true) {
            <Redirect to="/partner"/>
        }
        const partner = this.state.thisPartner === undefined ? null : this.state.thisPartner
        let el = this.state.loadingPartner === true ? <div style={{height:"60vh"}} className="conatiner-fluid p-0 d-flex justify-content-center align-items-center"><h1>Loading...</h1></div>
        :
        <div>
            <div className="container partner-new-page-wrapper">
                <div className="row m-0">
                    <div className="col-md-4 partner-new-page-img-wrapper">
                        <a href={partner.website} target="_blank"><img className="img partner-page-logo img-fluid" src={base + partner.logo} alt=""/></a>
                    </div>
                    <div className="col-md-8 partner-new-page-body">
                    <a href={partner.website} target="_blank" style={{ color:"#444" }}><h1 className="page-heading">{partner.title}</h1></a>
                        <p className="partner-new-page-content" dangerouslySetInnerHTML={{ __html: `${partner.body}` }}></p>
                    </div>
                </div>
            </div>
        </div>
        return (
            el
        );
    }
}

export default partnerPageNewTemplate;