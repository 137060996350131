import React, { Component } from 'react'
import Section2 from '../components/Section/PrimaryFA_section2';
import axios, { base } from '../axios-rsk';
import PfaMobile from '../components/forMobile/pfaMobile'

export default class PrimaryArea extends Component {

    state = {
        data: [],
        links: []
    }

    componentDidMount = async () => {
        const { data } = await axios.get('pillar/')
        this.setState({ data });
    }

    render() {
        return (
            <React.Fragment>
                <div className="pfa-desktop">
                {this.state.data === undefined ? null : this.state.data.map(m =>
                (<div id={m.slug}>
                    <Section2
                        heading={m.heading}
                        slug={m.slug}
                        subheading={m.subheading}
                        color={m.color}
                        align={m.align}
                        midImg={base + m.background}
                        frontImg={base + m.foreground}
                        description={m.description}
                        comp_1_title={m.component_1_title}
                        comp_1_url={m.component_1_url}
                        comp_2_title={m.component_2_title}
                        comp_2_url={m.component_2_url}
                        comp_3_title={m.component_3_title}
                        comp_3_url={m.component_3_url}
                    />
                </div>)
                )}
            </div>
            <div className="pfa-mobile">
            {this.state.data === undefined ? null : this.state.data.map(m =>
                <PfaMobile
                heading={m.heading}
                slug={m.slug}
                subheading={m.subheading}
                color={m.color}
                align={m.align}
                midImg={base + m.background}
                frontImg={base + m.foreground}
                description={m.description}
                comp_1_title={m.component_1_title}
                comp_1_url={m.component_1_url}
                comp_2_title={m.component_2_title}
                comp_2_url={m.component_2_url}
                comp_3_title={m.component_3_title}
                comp_3_url={m.component_3_url}
                />  
            )}
            </div>
            </React.Fragment>

        )
    }
}
