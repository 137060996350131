import React, { Component } from 'react';
import axios, { base } from '../axios-rsk';
import { Link } from 'react-router-dom';

class AllNotification extends Component {
    state = {
        allNotifications : [],
        loadingAllNotifications : true
    }
    componentDidMount = async() => {
        const { data:allNotifications } = await axios.get('/news/')
        this.setState({ allNotifications, loadingAllNotifications : false })
    }

    render() {
        const el = this.state.loadingAllNotifications === true ?
        <div style={{height:"60vh"}} className="container d-flex justify-content-center align-items-center pt-5"><h1 className="pt-5">Loading ...</h1></div>
        :
        <table className="notifications_table table table-hover">
            <thead>
                <tr className="table-active">
                    <th>Date</th>
                    <th>Title</th>
                </tr>
            </thead>
            <tbody>
            {this.state.allNotifications.map(m => 
                // <Link style={{color:"#1D8EB1"}} to={'/notification/'+m.slug}>
                    <tr className=''>
                        <td>
                            <h6 className="created_at">{m.created_at.split('T')[0].split('-').reverse().join('/')}</h6> 
                        </td>
                        <td>
                            <h5 className="mb-0 notification_title">
                            <Link style={{ color:"#222" }} to={'news/' + m.slug + '/'}>{m.title}</Link>
                            </h5>
                        </td>
                        
                        {/* <div className="ml-auto arrow_to_notification_page">
                            <i className="fa fa-arrow-right"></i>
                        </div> */}
                    </tr> 
                // </Link>   
            )}
            </tbody>
        </table>

        return (
            <div className="container-fluid all_notifications_wrapper p-0">
                <div className="container all_notifications_container">
                    <h1 className="pt-5">All News</h1>
                    {el}
                    {/* <Link style={{ color:"#444", fontSize:"15px" }} className="border rounded p-2" to="/">Home</Link> */}
                </div>
            </div>
        );
    }S
}

export default AllNotification;