import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import bannerimage from "../assets/test-image.jpg";
import latestimage from "../assets/download.jpeg";
import axios, {base} from '../axios-rsk';
import { motion } from 'framer-motion';
class TemplatePage extends Component {
    state = {
        thisAbout : []
    }
    componentDidMount = async() => {
        const { match: { params } } = this.props;
        const {data:about} = await axios.get(`/about/${params.url}/`)
        console.log(about)
        this.setState({thisAbout:about});
    }
    
    render() {
        const about = this.state.thisAbout === undefined ? null : this.state.thisAbout
        return (
            <div className="template-page2">
                <div className="banner-image">
                    <div className="heading-container">
                        <h2 className="page-subheading">About</h2>
                        <h1 className="page-heading">{about.title}</h1>
                    </div>
                    <img className="left_img"></img>
                    <img className="about_page_banner_img" src={base+about.image}></img>
                </div>

                <div className="main-content-container">
                    <div className="main-content">
                        {/* <h1 className="d-5">Random Text</h1> */}
                        <h3 className="page-path">Home &#x3e; About &#x3e; {about.title}</h3>
                        <Container>
                            <Row>
                                <Col lg={8}>
                                    <h2>{about.title}</h2>
                                    <p dangerouslySetInnerHTML={{__html: `${about.body}`}}></p>
                                </Col>
                                <Col lg={3}>
                                    <div className="latest-carousel">
                                        <p>Latest</p>
                                        <p className="arrows">&#60; &#62; </p>
                                        <div className="latest-carousel-content">
                                            <div className="date">30 March, 2020 | Events</div>
                                            <img src={latestimage} className="latest-carousel-image" />
                                            <p className="update-content">It was popularised in the 1960s
                                            with the release of Letraset sheets containing
                                            Lorem Ipsum passages, and more recently with desktop
                                            publishing software like Aldus PageMaker including
                                            versions of Lorem Ipsum.</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </div>


            </div>
        )
    }
}

export default TemplatePage;