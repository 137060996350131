import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import axios, { base } from "../../axios-rsk";

//Village page card
import VillagePageCard from '../../common/villagePageCard'

class VillageCardsContainer extends Component {
    state = {
        village : [],
        loadingVillage : true
    }
    componentDidMount = async() => {
        const { data: village } = await axios.get('/village/')
        this.setState({ village, loadingVillage : false })
    }
    
    render() {
        const village = this.state.village === undefined ? null : this.state.village
        let el = this.state.loadingVillage === true ? <div className="conatiner-fluid p-0 d-flex justify-content-center vh-100"><h1>Loading...</h1></div>
        :
        <div className="m-0 row">
                    {village.map(m => 
                        <div className="col-md-4 p-0">
                            <VillagePageCard name={m.name} slug={m.slug} image={base + m.image}/>
                        </div>    
                    )}
                </div>
        return (
            <div className="village_page_container">
                <div className="village_page_main_wrapper container-fluid p-0">
                    <div className="village_page_banner container-fluid p-0">
                        <h1>Village</h1>
                    </div>
                    <div className="container">
                        <h5 className="p-4" ><Link style={{color:"#333"}} to='/'>HOME</Link> &nbsp;|&nbsp; VILLAGE</h5>
                        {el}
                    </div>
                </div>
            </div>
        );
    }
}

export default VillageCardsContainer;