import React, {useState} from 'react'
import styled, {css} from 'styled-components';
import { Link } from 'react-router-dom';
const VillagePageCard = props => {
    return (
        <Link to={'/village/'+props.slug}>
            <div className="village_Page_card_template">
                <img src={props.image} className="img img-fluid village_page_card_image"/>
                <h1><span>{props.name}</span></h1>
            </div>
        </Link>
    );
}

export default VillagePageCard