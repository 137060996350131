import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import { HashLink as Link  } from 'react-router-hash-link'; 

import axios, { base } from '../../axios-rsk';

import Dropdown from '../../common/dropdown/dropdown';

import iitklogo from '../../assets/logoIITK.svg';
import styled from 'styled-components';
// import searchicon from '../../assets/ranjitSingh.jpg';
import GalleryPageImg from '../../common/galleryPageImg';
import ActivityPageCard from '../../common/activityPageCard';

import rskLogo from '../../assets/rsk-logo.png';

import $ from 'jquery'
export default class Navbar extends Component {
    state = {
        isOpen: false,
        width: 0,
        hamBurgerWidth: 0,
        hamBurgerOpacity: 0,
        sideBarOpacity: 0,
        partners: [],
        activities: [],
        gallery: [],
        about: [],
        story: [],
        news: [],
        notification: [],
        openNotificationBox: false,
        loadingNotification: true,
        loadingHamBurgerItems: true,
        dropdownWhat : [],
        why: [
            {
                title: "Dr. Ranjit Singh",
                slug: "dr-ranjit-singh"
            },
            {
                title: "Rozi Shiksha Kendra",
                slug: "rozi-shiksha-kendra"
            }
        ],
        what: [
            {
                title: "Agriculture",
                slug: "agriculture",
                slugPrefix: "activity"
            },
            {
                title: "Primary Education",
                slug: "primary-education",
                slugPrefix: "activity"
            },
            {
                title: "Vocational Training",
                slug: "arts-and-culture",
                slugPrefix: "activity"
            },
        ],
        who: [
            {
                title: "Partners",
                slug: "partner"
            },
            {
                title: "Team",
                slug: "team"
            },
        ],
    }
    ref = React.createRef()  //Sidebar ref
    ref1 = React.createRef() //Notification box ref
    hamburgerRef = React.createRef()

    componentDidMount = async () => {

        const state = this.state

        const { data: partners } = await axios.get('/partner/')
        this.setState({ partners })
        const { data: activities } = await axios.get('/activity/');
        this.setState({ activities });
        const { data: about } = await axios.get('/page/');
        this.setState({ about });
        const { data: story } = await axios.get('/story/');
        this.setState({ story });
        const { data: news } = await axios.get('/media/');
        this.setState({ news });
        const { data: gallery } = await axios.get('/gallery/');
        this.setState({ gallery });
        const { data: notification } = await axios.get('/news-recent/');

        const { data : pillar } = await axios.get('/pillar/')
        console.log(pillar)
        for (let i=0;i<pillar.length;i++) {
            this.state.dropdownWhat.push({ title:pillar[i].heading, slug:'#'+pillar[i].slug, slugPrefix: "",  })
        }

        this.setState({ notification, loadingNotification: false })

        if (!state.story.length && !state.gallery.length && !state.activities.length) {
            this.setState({ loadingHamBurgerItems: false })
        }
        document.addEventListener('click', this.handleClickOutside, true);
        document.addEventListener('click', this.handleClickOutsideNotificationBox, true);

    }
    componentWillUnmount = () => {
        return () => {
            document.removeEventListener('click', this.handleClickOutside, true);
            document.removeEventListener('click', this.handleClickOutsideNotificationBox, true);
        };
    }

    toggleSideBar = () => {
        {/*Function to open sidebar*/ }
        this.setState({ width: "240px", sideBarOpacity: 1 })
    }
    toggleHamBurger = () => {
        {/* Function to open HamBurger */ }
        this.setState({ hamBurgerWidth: "300px", hamBurgerOpacity: 1 })

    }

    toggleSideBarClose = () => {
        {/*Function to close sidebar*/ }
        this.setState({ width: 0, sideBarOpacity: 0 })
    }

    toggleHamBurgerClose = () => {
        this.setState({ hamBurgerOpacity: 0, hamBurgerWidth: 0 })
    }
    handleClickOutside = e => {
        {/*If clicked outside of sidebar, it will close*/ }
        if (this.ref.current && !this.ref.current.contains(e.target)) {
            {/*on clicking outside sidebar width will become 0*/ }
            this.setState({ width: 0, sideBarOpacity: 0 });
        }
        if (this.hamburgerRef.current && !this.hamburgerRef.current.contains(e.target)) {
            this.setState({ hamBurgerOpacity: 0, hamBurgerWidth: 0 });
        }
    };
    toggleNotificationBox = () => {
        {/*Function to open notification box*/ }
        this.setState({ openNotificationBox: !this.state.openNotificationBox })
    }
    handleClickOutsideNotificationBox = e => {
        {/*If clicked outside of notification box, it will close*/ }
        if (this.ref1.current && !this.ref1.current.contains(e.target)) {
            this.setState({ openNotificationBox: false });
        }
    };

    SideBarCloseOnLinkClick = () => {
        this.setState({ width: 0, opacity: 0 })
    }

    HamBurgerCloseOnLinkClick = () => {
        this.setState({ hamBurgerOpacity: 0, hamBurgerWidth: 0 })
    }

    SideBar = styled.div`
      ::-webkit-scrollbar {
          overflow-y: auto; 
          width: 10px;
          z-index: 1000;
          height: 10px; 
      }
      
      ::-webkit-scrollbar-thumb {
        overflow: overlay;
          background: #494949; 
          border-radius: 2px;
      }
        
      ::-webkit-scrollbar-thumb:hover {
        overflow: overlay;
          background: #DEDEDE; 
      } 
    `;

    hamBurger = styled.div`

      ::-webkit-scrollbar {
          overflow-y: auto; 
          width: 10px;
          z-index: 1000;
          height: 10px; 
      }
      
      ::-webkit-scrollbar-thumb {
        overflow: overlay;
          background: #DEDEDE; 
          border-radius: 2px;
      }
        
      ::-webkit-scrollbar-thumb:hover {
        overflow: overlay;
          background: #DEDEDE; 
      } 
    `;
    handleScroll(id){
        $('html, body').animate({
            scrollTop: $( '' + id ).offset().top -100
        }, 500);
        
        this.SideBarCloseOnLinkClick()
    }
    render() {

        //if sidebar is open, then main page scrollbar will hide
        document.body.style.overflow = this.state.width !== 0 || this.state.hamBurgerWidth !== 0 ? "hidden" : "visible"

        const notification = this.state.notification === undefined ? null : this.state.notification

        const hamBurger = this.state.loadingHamBurgerItems === true ?
            <div style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
                <h2 className="mt-4">Loading...</h2>
            </div>
            :
            <div>
                <li className="hamBurger-item close_hamBurger" onClick={this.toggleHamBurgerClose}>
                    <i className="fa fa-times"></i>
                </li>
                <div className="hamBurger_nav_links">
                    <Link onClick={this.HamBurgerCloseOnLinkClick} to="/">
                        <h2 style={{ textDecoration:"underline" }}>Home</h2>
                    </Link>
                </div>
                <div className="hamBurger_nav_links">
                    <Link onClick={this.HamBurgerCloseOnLinkClick} to="/activity">
                        <h2>Activities</h2>
                    </Link>
                </div>
                <div className="hamBurger_nav_links">
                    <Link onClick={this.HamBurgerCloseOnLinkClick} to="/gallery">
                        <h2>Gallery</h2>
                    </Link>
                </div>
                <div className="hamBurger_nav_links">
                    <Link onClick={this.HamBurgerCloseOnLinkClick} to="/document">
                        <h2>Documents</h2>
                    </Link>
                </div> 
                <div className="hamBurger_nav_links">
                    <Link onClick={this.HamBurgerCloseOnLinkClick} to="/story">
                        <h2>Success Stories</h2>
                    </Link>
                </div> 
                <div className="hamBurger_nav_links">
                    <Link onClick={this.HamBurgerCloseOnLinkClick} to="/all-media">
                        <h2>Media</h2>
                    </Link>
                </div>
                <div className="hamBurger_nav_links">
                    <Link onClick={this.HamBurgerCloseOnLinkClick} to="/news">
                        <h2>News</h2>
                    </Link>
                </div>
                <div className="hamBurger_nav_links">
                    <Link onClick={this.HamBurgerCloseOnLinkClick} to="/village">
                        <h2>Village</h2>
                    </Link>
                </div>
                <div className="hamBurger_nav_links">
                    <Link onClick={this.HamBurgerCloseOnLinkClick} to="/blogs">
                        <h2>Blogs</h2>
                    </Link>
                </div>
                <div className="hamBurger_nav_links">
                    <Link onClick={this.HamBurgerCloseOnLinkClick} to="/contact">
                        <h2>Contact Us</h2>
                    </Link>
                </div>
            </div>

        //Checking whether notifications are loaded
        let notificationSection = this.state.loadingNotification === true ? <div style={{ height: "20rem", width: "24.8rem" }} className="d-flex justify-content-center align-items-center">Loading...</div> :

            <div>
                <h3 style={{ fontFamily: "Source Sans Pro" }} className="p-2 border-bottom">News</h3>
                <ul>
                    {notification.map(m =>
                        <Link onClick={() => this.setState({ openNotificationBox: false })} style={{ color: "#444" }} to={'/news/' + m.slug + '/'}><li className="notification_item">{m.title}</li></Link>
                    )}
                    <Link onClick={() => this.setState({ openNotificationBox: false })} style={{ fontSize: "13px" }} className="read_all_notifications_external_link" to='/news'>
                        <li style={{ color: "#2a85ad" }}>
                            Read all
                            <i className="fa fa-external-link" />
                        </li>
                    </Link>
                </ul>

            </div>
        return (
            <div style={{ zIndex: 200, backgroundColor: "rgba(255,255,255,1)", position: "fixed", width: "100%", top: "0" }}>

                {/*Sidebar*/}
                <this.SideBar ref={this.ref} className="sideBar p-0" style={{ overflow: "auto", opacity: `${this.state.sideBarOpacity}`, width: `${this.state.width}`, height: "100vh" }}>
                    <li className="sideBar-item close_sideBar" onClick={this.toggleSideBarClose}><i className="fa fa-times"></i></li>
                    <div className="sidebar_nav_links">
                        <h2>Why</h2>
                        <ul>
                            {this.state.why.map(m =>
                                <Link onClick={this.SideBarCloseOnLinkClick} to={'/' + m.slug}><li>{m.title}</li></Link>
                            )}
                        </ul>
                    </div>
                    <hr />
                    <div className="sidebar_nav_links">
                        <h2 style={{ color: "black" }}>What</h2>
                        <ul>
                            {this.state.dropdownWhat.map(m =>
                                <a
                                // onClick={this.handleDropDownClose2}
                                onClick={this.handleScroll.bind(this, m.slug+'-mobile')}
                                href={'/' + m.slug + '/'}
                                style={{ textDecoration: "none", color: "black" }}
                                >
                                    <li>{m.title}</li>
                                </a>
                            )}
                        </ul>
                    </div>
                    <hr />
                    <div className="sidebar_nav_links">
                        <h2>Who</h2>
                        <ul>
                            {this.state.who.map(m =>
                                <Link onClick={this.SideBarCloseOnLinkClick} to={'/' + m.slug}><li>{m.title}</li></Link>
                            )}
                        </ul>
                    </div>
                    <hr />
                    <div className="sidebar_nav_links">
                        <Link onClick={this.SideBarCloseOnLinkClick} to="/story"><h2>Success Stories</h2></Link>    
                        <Link onClick={this.SideBarCloseOnLinkClick} to="/gallery"><h2>Gallery</h2></Link>
                        <Link onClick={this.SideBarCloseOnLinkClick} to="/all-media"><h2>Media</h2></Link>
                        <Link onClick={this.SideBarCloseOnLinkClick} to="/village"><h2>Village</h2></Link>
                        <Link onClick={this.SideBarCloseOnLinkClick} to="/blogs"><h2>Blogs</h2></Link>
                        <Link onClick={this.SideBarCloseOnLinkClick} to="/news"><h2>News</h2></Link>
                        <Link onClick={this.SideBarCloseOnLinkClick} to="/document"><h2>Documents</h2></Link>
                                            
                    </div>
                    <hr/>
                    <div className="sidebar_button_container">
                        <button><Link onClick={this.SideBarCloseOnLinkClick} style={{ color: "#444" }} to="/join"><h6>Join</h6></Link></button>
                        <button><Link onClick={this.SideBarCloseOnLinkClick} style={{ color: "#444" }} to="/donate"><h6>Donate</h6></Link></button>
                    </div>
                    <hr />
                </this.SideBar>

                {/* HamBurger Menu */}
                <this.hamBurger ref={this.hamburgerRef} className="hamBurger p-0" style={{ overflow: "auto", opacity: `${this.state.hamBurgerOpacity}`, width: `${this.state.hamBurgerWidth}`, height: "100vh" }}>
                    {hamBurger}
                </this.hamBurger>
                {/*navbar*/}
                <nav style={{ backgroundColor: "transparent", zIndex: "200", }} className="navbar navbar-expand-md d-flex">
                    <div className="menu-button-container" style={{backgroundColor:"", flexGrow:"1", position:"absolute"}}>
                        {/* <Link to="/"><img className="img img-fluid" src={iitklogo} style={{ color: "white" }} /></Link> */}
                        <h1 onClick={this.toggleHamBurger} className="navbar-menu-button">Menu</h1>
                        <button onClick={this.toggleSideBar} className="ml-auto navbar-hamburger-button navbar-toggler" type="button"
                            data-toggle="collapse"
                            aria-controls="nav__links" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="icon-bar top-bar" style={{ height: "0.4rem" }}></span>
                            <span className="icon-bar middle-bar" style={{ height: "0.4rem" }}></span>
                            <span className="icon-bar bottom-bar" style={{ height: "0.4rem" }}></span>
                        </button>
                        {/* <Img src={searchicon} alt="search icon"></Img> */}
                    </div>
                    <Link to="/" style={{color: "black", flexBasis:"100%", justifyContent:"center", textAlign:"center"}}>
                        <span className=" rsk_mainPage_Heading">
                            <img
                            className="img img-fluid rsk-logo" src={rskLogo} alt=""/>
                        </span>
                    </Link>
                    
                    <div className="collapse navbar-collapse" id="nav__links"style={{display:"absolute"}}>
                        <div className="navbar-nav" style={{backgroundColor:"", justifyContent:"end", marginLeft:"0"}}>
                            <div className="d-flex navbar-brand float-right dropdowns-container">
                                <Dropdown title="why" slugPrefix="" items={this.state.why} hasSlash="true"/>
                                <Dropdown title="what" slugPrefix="" items={this.state.dropdownWhat} hasSlash="true"/>
                                <Dropdown title="who" slugPrefix="" items={this.state.who} />
                            </div>
                        </div>
                    </div>


                    {/*Button for sidebar, will be shown if width < 768px*/}
                    {/* <svg className="search-icon ml-auto navbar-toggler" width="44" height="44" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 27C20.4036 27 26 21.1797 26 14C26 6.8203 20.4036 1 13.5 1C6.59644 1 1 6.8203 1 14C1 21.1797 6.59644 27 13.5 27Z" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M29 31L22 24" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> */}


                </nav>
            </div>
        );
    };

}


