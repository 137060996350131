import React, { Component } from 'react';

class Join extends Component {
    render() {
        return (
            <div>
                this is join page
            </div>
        );
    }
}

export default Join;