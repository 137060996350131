import React, { Component } from 'react'
import { Link } from 'react-router-dom';
class Donate_jon_btn extends Component {
    
    state = {
        opacity: "0"
    }
    // componentDidMount(){
    //     window.addEventListener('scroll', this.handleScroll);
    // }

    // componentWillUnmount(){
    //     window.removeEventListener('scroll',this.handleScroll);
    // }

    // handleScroll = (event)=>{
    //     if(window.pageYOffset > 863){
    //         this.setState({opacity:"1"});
    //     }
    //     if(window.pageYOffset < 863){
    //         this.setState({opacity:"0"});
    //     }
    // } 

    render() {
        return (
            
            <div style={{zIndex:"2000000"}} className="donate_join_btn">
                <Link to="/donate"><span>Donate</span></Link>
                <Link to="/join"><span>Join</span></Link>
            </div>
        );
    }
}

export default Donate_jon_btn;