import { m } from 'framer-motion';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import axios, { base } from '../../axios-rsk';

class Notification extends Component {
    state = {
        thisNotification : [],
        urlNull:false,
        loadingNotification : true
    }
    componentDidMount = async() => {
        const { match: { params } } = this.props;
        if (params === null)this.setState({ urlNull:true })
        const {data:Notification} = await axios.get(`/news/${params.url}/`)
        this.setState({ thisNotification:Notification, loadingNotification : false });
    }
    
    render() {
        
        if (this.state.urlNull === true) {
            <Redirect to="/news"/>
        }
        const thisNotification = this.state.thisNotification === undefined ? null : this.state.thisNotification
        const el = this.state.loadingNotification === true ? 
        <div style={{ height:"90vh"}} className="d-flex justify-content-center align-items-center"><h3>Loading Notification...</h3></div>
        :
        <div style={{ minHeight:"100vh" }} className="container-">
            <span className="back_button">
                <Link to="/news"><i className="fa pr-1 fa-arrow-left"></i> back</Link></span>
            <h1 className="mt-4">{thisNotification.title}</h1>
            <h5>{thisNotification.created_at === undefined ? null : thisNotification.created_at.split('T')[0].split('-').reverse().join('/')}</h5>
            <p className="notification_body table table-hover list-group" dangerouslySetInnerHTML={{__html: `${thisNotification.body}`}}></p>
        </div>
        
        return (
            <div className="each_notification container-fluid p-0">
                {el}
            </div>
        );
    }
}

export default Notification;

//[{thisNotification.created_at.split('T')[0].split('-').reverse().join('/')}]