import React, { Component } from 'react';
import Slider from "react-slick";
import axios, { base } from '../../axios-rsk';


class Testimonial extends Component {
    state = {
        settings: {
            className: "center",
            centerMode: true,
            // infinite: true,
            autoplay:true,
            centerPadding: "60px",
            slidesToShow: 3,
            speed: 500,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        },
        currentSlide : 0,
        tm : [],
        loadingTm:true
      };

    componentDidMount = async() => {
        const { data : tm } = await axios.get('/home/testimonial/')
        this.setState({ tm, loadingTm:false })
    }

    timeout = delay => {
        return new Promise( res => setTimeout(res, delay) );
    }

    ImageInFocus = async(e) => {


        //image which is clicked
        console.log(this.state.currentSlide, parseInt(e.target.id))
        if (this.state.currentSlide === this.state.tm.length - 1 && parseInt(e.target.id) === 0) {
            
             this.slider.slickNext()
        }
        if (this.state.currentSlide === 0 && parseInt(e.target.id) ===  this.state.tm.length - 1) {
            
            this.slider.slickPrev()
       }
        else if (e.target.id > this.state.currentSlide) {
           return this.slider.slickNext()
        }
        else if (e.target.id < this.state.currentSlide) {
           return this.slider.slickPrev()
        }
        
    }
    
    nextClick = e => {
        this.setState({ currentSlide : e })
    }
    
    render() {
        const tm = this.state.tm === undefined ? null : this.state.tm;
        const currentSlide = this.state.currentSlide === undefined ? null : this.state.currentSlide
        return (
            <div className="container-fluid slick_carousel">
                <h1>Testimonials</h1>
                <hr/>
            <div className="container mt-5">
                <Slider ref={c => (this.slider = c)} {...this.state.settings} afterChange={this.nextClick}>
                    {tm.map((m, id) => 
                        <div>
                            <div className="carousel_item_wrapper_">
                                <div className="container">
                                    <img id={id} onClick={ this.ImageInFocus } style={{border:`3px solid #34495e`}} className="slick-carousel-image mx-auto d-block img img-fluid" src={base + m.image} />  
                                    <hr/>     
                                </div>
                            </div>
                        </div>
                    )}
                </Slider>
                <div className="slick_carousel_content">
                    <h4 className="text-center">{tm[currentSlide] === undefined ? null : tm[currentSlide].name}</h4>
                    <h5 className="text-center">{tm[currentSlide] === undefined ? null : tm[currentSlide].designation}</h5>
                    <h3 className="text-center">{tm[currentSlide] === undefined ? null : tm[currentSlide].message}</h3>
                </div>
            </div>
        </div>
        );
    }
}

export default Testimonial;