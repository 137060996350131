import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
const FullImgTemplate = props => {
    return (
            <div style={{ minHeight:"100vh" }}>
            <div className="each_Item_activity_page_banner container-fluid p-0">
                <img src={props.image} alt={props.imgAlt}/>
                <motion.div style={{ backgroundColor:props.backgroundColor }} className="banner_headings" initial={{x:-100}} animate={{x:0}} transition={{duration:0.5, ease: "easeOut"}}>
                    <h1 style={{ color:props.headingColor }}>{props.name}</h1>
                </motion.div>
            </div>
            <div className="container activity_page_content_container ">
            <h4><Link to="/" style={{color:"#444"}}>HOME</Link> &nbsp;>&nbsp;<Link to={'/' + props.slug} style={{color:props.linkColor}}><span style={{ textTransform:"uppercase" }}>{props.cardContainerName}</span></Link> &nbsp;>&nbsp; <span style={{ textTransform:"uppercase" }}>{props.linkName}</span></h4>
                <p className="activity_page_content" dangerouslySetInnerHTML={{__html: `${props.body}`}}></p>
            </div>
        </div>
    );
}

export default FullImgTemplate