import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import bannerimage from "../../assets/test-image.jpg";
import latestimage from "../../assets/download.jpeg";
import axios, { base } from '../../axios-rsk';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
class About extends Component {
    state = {
        thisAbout: [],
        loadingThisAbout: true
    }
    componentDidMount = async () => {
        const { match: { params } } = this.props;
        const { data: about } = await axios.get(`/page/${params.url}/`)
        this.setState({ thisAbout: about, loadingThisAbout: false });
    }

    render() {
        const about = this.state.thisAbout === undefined ? null : this.state.thisAbout
        let el = this.state.loadingThisAbout === true ? <div style={{ height: "75vh" }} className="conatiner-fluid p-0 d-flex align-items-center justify-content-center"><h1>Loading...</h1></div>
            :
            <div className="template-page2">
                <div className="banner-image">
                    <div className="heading-container">
                        <h2 className="page-subheading">About</h2>
                        <h1 className="page-heading">{about.title}</h1>
                    </div>
                    <div className="left_img "></div>
                    <img className="about_page_banner_img" src={base + about.image}></img>
                </div>

                <div className="main-content-container">
                    <div className="main-content">
                        <h3 className="page-path"><Link to="/">Home</Link> &#x3e; {about.title}</h3>
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <h2>{about.title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: `${about.body}` }}></p>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </div>
            </div>
        return (el)
    }
}

export default About;