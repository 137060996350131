import React, { Component } from 'react';
import axios, { base } from '../../axios-rsk'
import { Link } from 'react-router-dom'
import ActivityPageCard from '../../common/activityPageCard'
class ActivityPageContainer extends Component {
    state = {
        activity : [],
        loadActivity : false
    }
    componentDidMount = async() => {
        const { match: { params } } = this.props;
        const {data : activity} = await axios.get('/activity/')
        this.setState({ activity, loadActivity : true })
    }
    
    render() {
        const activity = this.state.activity === undefined ? null : this.state.activity
        let el = this.state.loadActivity === false ? <div className="conatiner-fluid p-0 d-flex justify-content-center vh-100"><h1>Loading...</h1></div>
        :
        <div className="m-0 row">
                    {activity.map(m => 
                        <div className="col-md-4 p-0">
                            <ActivityPageCard title={m.title} slug={m.slug} color={m.color} thumbnail={base + m.thumbnail} logo={base + m.logo}/>
                        </div>    
                    )}
                </div>
        return (
            <div className="activity_page_main_wrapper container-fluid p-0">
                <div className="activity_page_banner container-fluid p-0">
                    <h1>Activities</h1>
                </div>
                <div className="container pb-5">
                    <h5 className="p-4" ><Link style={{color:"#333"}} to='/'>HOME</Link> &nbsp;|&nbsp; ACTIVITIES</h5>
                    {el}
            </div>
            </div>
        );
    }
}
export default ActivityPageContainer