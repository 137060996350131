import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import axios, { base } from '../../axios-rsk';

import NewsPageCard from '../../common/newsComponentCard2';

class News extends Component {
    state = {
        news : [],
        loadingNews : true 
    }
    componentDidMount = async() => {
        const { data: news } = await axios.get('/media/')
        this.setState({ news, loadingNews:false })
    }
    
    render() {
        let news = this.state.news === undefined ? null : this.state.news
        let el = this.state.loadingNews === true ? 
        <div className="vh-100 d-flex m-5 pb-3 justify-content-center"><h1>Loading...</h1></div> 
        : 
        <div className="container pb-5">
            <h5 className="p-4"><Link style={{color:"#444"}} to="/">HOME</Link> &nbsp;|&nbsp; NEWS</h5>
            <div className="row m-0">
                {news.map(m => 
                    <NewsPageCard title={m.title} image={base + m.image} slug={m.slug} newspaper={m.newspaper} published_on={m.published_on} body={m.body}/>    
                )}                    
            </div>
        </div> 
        
        return (
            <div className="container-fluid news_Page_wrapper p-0">
                <div className="news_page_banner container-fluid p-0">
                    <h1>Media</h1>
                </div>
                <div className="container newsPage_container p-0">
                    {el}
                </div>
            </div>
        );
    }
}

export default News;