import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import axios, { base } from '../../axios-rsk';

//gallery page single image component
import GalleryPageImg from '../../common/galleryPageImg';

class Gallery extends Component {
    state = {
        gallery : [],
        loadingGallery : true
    }
    componentDidMount = async() => {
        const { data : gallery } = await axios.get('/gallery/')
        this.setState({ gallery, loadingGallery : false });
    } 
    
    render() {
        const gallery = this.state.gallery === undefined ? null : this.state.gallery
        const el = this.state.loadingGallery === true ? <div style={{ height:"66vh" }} className="d-flex justify-content-center align-items-center"><h1>Loading...</h1></div>
        :
        <div className="container">
            <h5 className="p-2" ><Link style={{color:"#333"}} to='/'>HOME</Link> &nbsp;|&nbsp; GALLERY</h5>
            <div className="row m-0">
                {gallery.map(m => 
                    <div className="col-md-4 p-0">
                        <a href={m.url} target="_blank">
                            <GalleryPageImg title={m.title} date={m.date} image={base + m.image} url={m.url}/>
                        </a> 
                    </div>  
                )}
            </div>
        </div>
        return (
            <div className="gallery_page_container">
                <div className="container-fluid gallery_page_main_wrapper gallery_container p-0">
                <div className="gallery_page_banner container-fluid p-0">
                        <h1>Gallery</h1>
                    </div>
                    
                {el}
            </div>
            </div>
        );
    }
}

export default Gallery;