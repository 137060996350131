import React, { Component } from 'react'
import axios from "../axios-rsk";

import Card from "../components/Card/HomePageActivityCard";
class ActivitesSection extends Component {

    state = {
        activities: [],
    }
    componentDidMount() {
        axios.get('/activity/')
            .then(response => {
                const recievedActivities = [];
                for (let key in response.data) {
                    recievedActivities.push({
                        ...response.data[key]
                    })
                }
                this.setState({ activities: recievedActivities });
            })
    }

    render() {
        const el = <div className="card-container row d-flex justify-content-center m-0">
            {
                this.state.activities.slice(0, 6).map(activity => {
                    return (
                        <div className="col-md-4 column-content p-0">
                            <Card
                                color={activity.color}
                                imageURL={activity.thumbnail}
                                title={activity.title}
                                slug={activity.slug}
                                logo={activity.logo}
                            />
                        </div>

                    )
                })
            }
        </div>

        return (
            <div style={{ marginBottom:"14rem" }} className="container-fluid p-0 homePage_activity_cards_background_color card-container" >
                <div className="container card-container" >
                    <h1 className="activity_title text-center">Activities</h1>
                    <hr />
                    {el}
                </div>
            </div>
        )
    }
}
export default ActivitesSection;