import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../components/UI-Components/Modal/Modal';
class NewsPageCard extends Component {
    state = {
        modalOpened: false
    }

    newsClicked = () => {
        console.log("news clicked");
        const newState = !this.state.modalOpened;
        this.setState({
            modalOpened: newState
        });
        console.log(this.state.modalOpened);
    }

    newsCloseHandler = () => {
        this.setState({
            modalOpened: false
        })
    }
    render() {
        return (
            <>
                <Modal show={this.state.modalOpened} modalClosed={this.newsCloseHandler}>
                    <a href={this.props.image}>
                        <img src={this.props.image}></img>
                    </a>
                </Modal>
                <div className="col-md-6 p-0" style={{ cursor: "pointer" }}>

                    <div onClick={this.newsClicked}>
                        <div className="newsPage_wrapper">
                            <img src={this.props.image} className="img img-fluid news_card_img" />
                            <div className="newsPage_card_content">
                                <h3 className="text-center">{this.props.title}</h3>
                                <h5 className="text-center" style={{ fontStyle: "italic" }}>&mdash;&nbsp;{this.props.newspaper}</h5>
                            </div>
                            <div className="newsPage_card_date">
                                <h4 className="mb-0">{this.props.published_on}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}
export default NewsPageCard