import Axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import axios, { base } from '../../axios-rsk';
import FullImgTemplate from '../../common/FullImgBackTemplate';
class EachSuccessStory extends Component {
    state = {
        story : [],
        loadingStory:true
    }
    componentDidMount = async() => {
        window.scrollTo(0, 0);
        const { match: { params } } = this.props;
        const {data:story} = await axios.get(`/story/${params.url}/`)
        this.setState({ story, loadingStory : false });
    }
    
    render() {
        const story = this.state.story === undefined ? null : this.state.story
        const el = this.state.loadingStory === true ? 
        <div style={{ height:"90vh" }} className="container d-flex justify-content-center align-items-center">
            <h1>Loading...</h1>
        </div>
        :
        <div>
            <div className="each_Item_activity_page_banner container-fluid p-0">
                <img src={ base + story.image } alt={story.title}/>
            </div>
            <div className="container activity_page_content_container ">
            {/* <h4><Link to="/" style={{color:"#444"}}>Home</Link> &nbsp;>&nbsp;<Link to='/story' style={{color:"#444"}}><span>Story</span></Link> &nbsp;>&nbsp; <span>{story.title}</span></h4> */}

            <h4 className="text-center">{story.title}</h4>
            <p className="activity_page_content" dangerouslySetInnerHTML={{__html: `${story.body}`}}></p>
            </div>
        </div>
        return (
            <div>
                {el}
            </div>
        );
    }
}

export default EachSuccessStory;