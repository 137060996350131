import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Carousel from '../components/Carousel/HomePageCarousel';
// import ReactPageScroller from 'react-page-scroller';
import axios, { base } from '../axios-rsk';
import ActivitiesSection from '../containers/Activities';

import TestimonialSection from '../containers/Testimonial';
import RanjitSinghSection from '../components/HomePageRanjitSinghSection/RanjitSinghSection';

import PrimaryFunctionSection from '../containers/Section';
import PrimaryArea from '../containers/PrimaryArea';
import TestimonialPage from '../containers/testimonialPage';

import TestimonialCarousel from "../components/TestimonialCarousel/TestimonialCarousel";

import HomePageFigure from '../components/HomePageFigure/HomePageFigure';
import HomePageCarouselContainer from '../containers/HomePageCarouselContainer';
import Testimonial from '../components/TestimonialCarousel2.0/testimonial';

import iitklogo from '../assets/iitk.png'
import rsklogo from '../assets/rsk-logo.png'
export default class Homepage extends Component {

    state = {
        homePageData: [],
        homePageCarousel: [],
        loadingHomePageData: true,
        loadingRecentNews : true,
        newsRecent : []
    }

    componentDidMount = async () => {
        const { data: homePageData } = await axios.get('/home/one/');
        this.setState({ homePageData, loadingHomePageData: false });
        const { data: homePageCarousel } = await axios.get('/home/carousel/')
        this.setState({ homePageCarousel })
        const { data: newsRecent } = await axios.get('/news-recent/')
        this.setState({ newsRecent, loadingRecentNews : false })
    }

    render() {

        {/*Home page data and home page carousel*/ }
        const homePageData = this.state.homePageData[0] === undefined ? [] : this.state.homePageData[0];
        const homePageCarousel = this.state.homePageCarousel === undefined ? null : this.state.homePageCarousel
        const recentNews = this.state.loadingRecentNews === true ? <div><h3 className="text-white">Loading Recent news...</h3></div>
        :
        <div>
            <h3 className="recent-news-heading">Recent News</h3>
                {this.state.newsRecent === undefined ? null : this.state.newsRecent.map(m => 
                    <Link to={"/news/" + m.slug}>
                        <p className="recent-news-element">
                            {m.title}
                        </p> 
                    </Link>   
                )}
        </div>
        const ranjitSinghData = this.state.loadingHomePageData === true ? <div></div>
            :
            <RanjitSinghSection image={homePageData.ranjit_singh_image} title={homePageData.ranjit_singh_slogan} />
        {/*extracting extension from home page banner*/ }
        const ext = this.state.homePageData[0] === undefined ? [] : (this.state.homePageData[0].home_page_banner.split(".")[1]);

        {/*home page banner*/ }
        let banner = this.state.loadingHomePageData === true ?
            <div style={{ height: "70vh" }} className="container d-flex justify-content-center align-items-center"><h1>Loading...</h1></div>
            :
            (ext === "jpg" || ext === "png" || ext === "svg" || ext === "jpeg" || ext === "dng" || ext === "raw")
                ?
                <div className="homepage_banner_container" style={{ backgroundColor: "black" }}>

                    {/*If extension is of type image then return this component*/}
                    <div className="banner-upper-container">
                        <div className="banner-upper-container-heading">
                            <h1 style={{ color: homePageData.rsk_color }} className="homepage_banner_heading">
                                Ranjit Singh Rozi Shiksha Kendra
                            </h1>
                            <h1 style={{ color: "white" }} className="homepage_banner_heading">
                                Indian Institute of Technology Kanpur
                            </h1>
                            <h4 style={{ color: homePageData.sub_heading_color }} className="homepage_banner_subheading">Empower our future</h4>
                            <div className="row m-0 homepage_logos">
                                <img className="p-1 m-2" src={rsklogo} style={{ width:"10vh", backgroundColor:"rgba(255, 255, 255, 0.86)", borderRadius:"50%"}} alt=""/>
                                <img className="p-1 m-2" src={iitklogo} style={{ width:"10vh", backgroundColor:"rgba(255, 255, 255, 0.86)", borderRadius:"50%" }} alt=""/>
                            </div>
                        </div>
                        <div className="banner-upper-container-latest">
                            <h3 className="recent-news-heading">Recent News</h3>
                            {this.state.newsRecent === undefined ? null : this.state.newsRecent.map(m => 
                                <Link to={m.slug}>
                                    <p className="recent-news-element">
                                        {m.title}
                                    </p> 
                                </Link>   
                            )}
                            
                        </div>
                        {/* <div className="banner-upper-container-latest-for-mobile">
                            <h3 className="recent-news-heading">Recent News</h3>
                            <p className="recent-news-element">The day starts with a soft breeze and this line will
                            continue atleast for few more words</p>
                            <p className="recent-news-element">The day starts with a soft breeze and this line will
                            continue atleast for few more words</p>
                        </div> */}
                    </div>
                    <div className="banner-lower-container">
                        <div className="homepage-slogan-container">
                            <h1 className="homepage-slogan">{homePageData.ranjit_singh_slogan}</h1>
                        </div>
                        <div className="image-banner-lower-container">
                            <img className="banner-lower-image" src={base + homePageData.ranjit_singh_image} alt="Ranjit Singh Image"></img>
                            <h2 className="banner-lower-image-caption">Dr. Ranjit Singh, Founder</h2>
                        </div>
                    </div>
                    <div className="banner-lower-container-for-mobile">
                        <div className="image-banner-lower-container">
                            <div className="row d-flex justify-content-center m-0 homepage-slogan-container">
                                <h1 className="homepage-slogan">"{homePageData.ranjit_singh_slogan}"</h1>
                                {/* <svg className="down-pointing-arrow-to-image" width="37" height="22" viewBox="0 0 37 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M9.5 20C14.1988 14.7484 8.50449 8.87614 1.78728 4.59369C-0.0534449 3.42016 0.743777 0 2.92676 0H34.4797C36.4037 0 37.2178 2.44154 35.6685 3.58249C22.1762 13.5186 3.46701 26.7428 9.5 20Z" fill="black"/>
                                </svg> */}
                            </div>
                            <img className="banner-lower-image" src={base + homePageData.ranjit_singh_image} alt="Ranjit Singh Image" />
                            {/* <h2 className="banner-lower-image-caption">Dr. Ranjit Singh, Founder</h2> */}
                        </div>
                    </div>
                    <img className="img img-fluid" src={base + homePageData.home_page_banner} style={{ zIndex: "0", opacity: homePageData.opacity }} />
                </div>
                :
                <div className="embed-responsive homepage_banner_container embed-responsive-16by9" >

                    {/*else if extension is of type video then return this component*/}

                    <div className="banner-upper-container">
                        <div className="banner-upper-container-heading">
                            <h1 style={{ color: homePageData.rsk_color }} className="homepage_banner_heading">
                            Ranjit Singh Rozi Shiksha Kendra
                            </h1>
                            <h1 style={{ color: homePageData.rsk_color }} className="homepage_banner_heading1">
                            Indian Institute of Technology Kanpur
                            </h1>
                            <h4 style={{ color: homePageData.sub_heading_color }} className="homepage_banner_subheading">Empower our future</h4>
                            <div className="row m-0 homepage_logos">
                                <img className="p-1 m-2" src={rsklogo} style={{ width:"10vh", backgroundColor:"rgba(255, 255, 255, 0.86)", borderRadius:"50%"}} alt=""/>
                                <img className="p-1 m-2" src={iitklogo} style={{ width:"10vh", backgroundColor:"rgba(255, 255, 255, 0.86)", borderRadius:"50%" }} alt=""/>
                            </div>
                        </div>
                        <div className="banner-upper-container-latest">
                            {recentNews}
                        </div>
                    </div>
                    <div className="banner-lower-container">
                        <h1 className="homepage-slogan">"{homePageData.ranjit_singh_slogan}"</h1>
                        <div className="image-banner-lower-container d-flex justify-content-center flex-column align-items-center">
                            <img className="banner-lower-image" src={base + homePageData.ranjit_singh_image} alt="Ranjit Singh Image"></img>
                            <h2 className="banner-lower-image-label">Dr. Ranjit Singh, Founder</h2>
                        </div>
                    </div>
                    <div className="banner-lower-container-for-mobile">
                        <div className="image-banner-lower-container">
                            <div className="row d-flex justify-content-center m-0 homepage-slogan-container">
                                <h1 className="homepage-slogan">"{homePageData.ranjit_singh_slogan}"</h1>
                                {/* <svg className="down-pointing-arrow-to-image" width="37" height="22" viewBox="0 0 37 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M9.5 20C14.1988 14.7484 8.50449 8.87614 1.78728 4.59369C-0.0534449 3.42016 0.743777 0 2.92676 0H34.4797C36.4037 0 37.2178 2.44154 35.6685 3.58249C22.1762 13.5186 3.46701 26.7428 9.5 20Z" fill="black"/>
                                </svg> */}
                            </div>
                            <img className="banner-lower-image" src={base + homePageData.ranjit_singh_image} alt="Ranjit Singh Image" />
                            {/* <h2 className="banner-lower-image-caption">Dr. Ranjit Singh, Founder</h2> */}
                        </div>
                    </div>
                    <video autoPlay loop muted={true} src={base + homePageData.home_page_banner} className="homepage-video " style={{ opacity: homePageData.opacity }} />
                </div>
        return (
            <div className="m-0" onScroll={this.onScrollHandler}>
                <div style={{ backgroundColor: "black" }}>
                    {banner}
                </div>
                {/* {ranjitSinghData} */}
                {/* <Carousel carousel={homePageCarousel} />
                <HomePageFigure image={homePageData.primary_activity_image} /> */}
                <PrimaryArea />
                <ActivitiesSection />
                {/* <button onClick={this.gotToBottom}>Bottom</button> */}
                {/* <TestimonialPage />
                <TestimonialSection /> */}
                {/* <TestimonialCarousel/> */}
                <Testimonial />
                {/* <HomePageCarouselContainer /> */}

            </div>
        )
    }
}
