import React, { Component } from 'react';
import {Carousel} from 'react-bootstrap'
import axios, { base } from '../axios-rsk';
class HomePageCarouselContainer extends Component {
    state = {
        carouselItem : []
    }
    componentDidMount = async() => {
        const {data : carouselItem} = await axios.get('/home/testimonial/')
        console.log(carouselItem)
        this.setState({ carouselItem }) 
    }
    
    render() {
        const carouselItem = this.state.carouselItem === undefined ? null : this.state.carouselItem
        return (
            <div className="container-fluid p-0 carousel_container_">
                <h1 style={{color:"#495968"}}>Testimonials</h1>
                <hr />
                <Carousel interval={4000}>
                    {carouselItem.map(m => 
                        <Carousel.Item>
                            <div className="carousel_item_wrapper_">
                                <div className="container">
                                    <img style={{border:`3px solid ${m.color}`}} className="mx-auto d-block img img-fluid" src={base + m.image} />  
                                    <h4 className="text-center">{m.name}</h4>
                                    <h5 className="text-center">{m.designation}</h5>
                                    <hr/>
                                    <h6 className="font-weight-bold">{m.message}</h6>     
                                </div>
                            </div>
                        </Carousel.Item>  
                    )}
                </Carousel>
            </div>
        );
    }
}

export default HomePageCarouselContainer;