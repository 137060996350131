import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import bannerimage from "../assets/test-image.jpg"
class TemplatePage extends Component {
    render() {
        return (
            <div className="template-page">
                <img src={bannerimage}></img>

                <div className="main-content-container">
                    <div className="main-content">
                        <h1 className="d-5">Random Text</h1>
                        <Container>
                            <Row>
                                <Col lg={9}>
                                    <h2>Heading 1</h2>
                                    <p></p>

                                </Col>
                                <Col lg={3}>
                                    <div className="latest-carousel">
                                        <p>Latest</p>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </div>


            </div>
        )
    }
}

export default TemplatePage;