import React, { Component } from 'react';
import axios, { base } from '../../axios-rsk'
import FullImgTemplate from '../../common/FullImgBackTemplate';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
class EachVillage extends Component {
    state = {
        thisVillage : [],
        loadingThisVillage : true
    }
    componentDidMount = async() => {
        const { match: { params } } = this.props;
        const {data:thisVillage} = await axios.get(`/village/${params.url}/`)
        this.setState({ thisVillage, loadingThisVillage : false });
    }
    render() {
            const thisVillage = this.state.thisVillage === undefined ? null : this.state.thisVillage
            let el = this.state.loadingThisVillage === true ? 
            <div style={{height:"60vh"}} className="container d-flex justify-content-center align-items-center"><h1>Loading...</h1></div>
            :
            <FullImgTemplate
                image={ base + thisVillage.image }
                imgAlt={thisVillage.name}
                name={thisVillage.name}
                body={thisVillage.body}
                cardContainerName="Village"
                linkName={thisVillage.name}
                slug="village"
                headingColor="white"
                linkColor="#444"
                backgroundColor="rgba(128,10,60, 0.6)"
            />
        return (
            el
        );
    }
}

export default EachVillage;