import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Contact extends Component {
    render() {
        return (
            <div className="contact_page_container container-fluid p-0">
                <div className="container">
                    <h1 className="contact_page_heading text-center"><span>Contact Us</span></h1>
                    <div className="row d-flex justify-content-center mt-5">
                        <div className=" col-md-6 ">
                            <div className="contact_page_wrapper">
                                <h1 className="text-center">Rozi Siksha Kendra</h1>
                                {/* <h2 className="text-center">Email: <span><a href="mailto:rsk.iitk.ac.in">rsk@iitk.ac.in</a></span></h2> */}
                                <h3 className="text-center">Phone No: +91-9123456789</h3>
                                <h4 className="text-center">Address: WLE 114, IIT Kanpur</h4>
                            </div>
                            <Link to="/">
                                <span>Back to home</span>
                            </Link>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Contact;