import React, { Component } from 'react'
import axios, {base} from '../axios-rsk';
import { motion } from 'framer-motion';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ScrollReveal from 'scrollreveal';
export default class Carousel extends Component {

    state = {
        testimonials:[],
        index: 0,
        __carousel_class:"container container-items"
    }
    componentDidMount = async() => {
        const {data: testimonials} = await axios.get('/home/testimonial/');
        console.log(testimonials)
        this.setState({testimonials})
        ScrollReveal().reveal('._carousel-container', { scale: 0.9 });
        // this.autoPlayTestimonialSection();
    }
    
    handleRightSide = () => {
        let index = this.state.index;
        let items = this.state.testimonials;
        if(index === items.length - 1)
        this.setState({index:0});
        else
        this.setState({index:this.state.index+1});
    }
    handleLeftSide = () => {
        let index = this.state.index;
        let items = this.state.testimonials;
        if(index === 0)
        this.setState({index:items.length-1});
        else 
        this.setState({index:this.state.index-1});
    }
    renderDots(arrayLength) {
        let dots = [];
        for (let i=0;i<arrayLength;i++) {
            // dots.push(<i key={i} value={i} onClick={e => this.changeTestimoinal(e, i)} className="fa fa-circle"></i>)
            dots.push(
                <div>
                    <input name="carousel-dots" type="radio" className="radio" id={i} value={i} onClick={e => this.changeTestimoinal(e)} />
                    <label className="radio-labels" defaultChecked={this.state.index === i} htmlFor={i}><i className="fa fa-circle"></i></label>
                </div>
            )
        }
        return dots;
    }
    changeTestimoinal = (e) => {
        this.setState({index:e.target.value});
    }
//     autoPlayTestimonialSection(){ 
//         let index = this.state.index;
//         let items = this.state.testimonials;
//     setInterval(() => { 
//         if(this.state.index === this.state.testimonials.length - 1)
//         this.setState({index:0});
//         this.setState({index: this.state.index+1});
//         // else if(index === 0)
//         // this.setState({index:items.length-1});
        
//     }, 5000) 
//   } 

    render() {
        let index = this.state.index;
        let item = this.state.testimonials;
        let k = item[index] === undefined ? [] : item[index];
        let carousel_class = this.state.__carousel_class
        console.log(index);
        return (

            <div className="container-fluid p-5 mt-5" >
                <div className="testimonial-heading-container container mt-5">
                    
                <h1 className="testimonial-heading">Testimonials</h1>
                </div>
                
                <div className="container _carousel-container p-0">
                    <div className="left-arrow" onClick={this.handleLeftSide} ><i className="fa fa-3x fa-angle-left"></i></div>
                    <div className="right-arrow" onClick={this.handleRightSide}><i className="fa fa-3x fa-angle-right"></i></div>
                    <div className={carousel_class}>
                            <TransitionGroup>
                                <CSSTransition
                                    key={index}
                                    timeout={1000}
                                    classNames="messageout"
                                    >
                                    <div key="transition-group-content">
                                        <div className="row testimonials-section">
                                            
                                            <div className="col-md-4 m-0 p-0">
                                                
                                                <img className="img img-fluid w-100" src={base + k.image} alt=""/>
                                            </div>
                                            <div className="col-md-8 p-3">
                                            <h1 className="p-5">{k.name}</h1>
                                                <p>{k.message}</p>
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                        <div className="dot-container">
                            {this.renderDots(item.length).map((m, i) => m)}
                        </div>
                </div>
            </div>
        )
    }
}
