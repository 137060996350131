import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { base } from '../axios-rsk';
const GalleryPageImg = props => {

    return (
            <div className="gallery m-3">
                <img src={props.image} className="img img-fluid "/>
                <div className="image_overlap"></div>
                <h4><span className="pr-2">{props.date}&nbsp;&nbsp;|</span>{props.title}</h4>
            </div>

    );
}

export default GalleryPageImg