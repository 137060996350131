import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';

import axios, {base} from '../../axios-rsk';

import { Container, Row, Col } from 'react-bootstrap';
import partnerPageNewTemplate from '../../common/partnerPageNewTemplate';
export default class Partner extends Component {

    state = {
        thisPartner: [],
        urlNull:false,
        loadingPartner:true
    }

    componentDidMount = async () => {
        const { match: { params } } = this.props;
        if (params === null)this.setState({ urlNull:true })
        const { data: thisPartner } = await axios.get(`/partner/${params.url}/`)
        this.setState({ thisPartner, loadingPartner:false });
        console.log(params)
    }

    render() {
        if (this.state.urlNull === true) {
            <Redirect to="/partner"/>
        }
        const partner = this.state.thisPartner === undefined ? null : this.state.thisPartner
        let el = this.state.loadingPartner === true ? <div style={{height:"60vh"}} className="conatiner-fluid p-0 d-flex justify-content-center align-items-center"><h1>Loading...</h1></div>
        :
        <div className="partner_page">
            <div className="banner-image">
                <div className="heading-container">
                    <h1 className="page-heading">{partner.title}</h1>
                </div>
                <img className="left_img"></img>
                <img className="about_page_banner_img" src={base+partner.logo}></img>
            </div>

            <div className="main-content-container">
                <div className="main-content">
                    {/* <h1 className="d-5">Random Text</h1> */}
                    <h3 className="page-path"><Link to="/">Home</Link> &#x3e; <Link to="/partner/">Partner</Link> &#x3e; {partner.title}</h3>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <h2 classname="partner_title">{partner.title}</h2>
                                <p dangerouslySetInnerHTML={{__html: `${partner.body}`}}></p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>

        return (el)
    }
}