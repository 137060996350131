import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import axios, { base } from '../axios-rsk'

class BlogContainer extends Component {
    
    state = {
        blogs : []
    }

    componentDidMount = async() => {
        const {data : blogs} = await axios.get('/blogs/')
        this.setState({ blogs })
    }
    
    render() {

        const blogs = this.state.blogs === undefined ? null : this.state.blogs

        return (
            <div className="container-fluid blog-list-wrapper p-0">
                <div className="container blog-list-container">
                <h2 style={{fontSize:"26px"}} className="mb-1 text-center">Blogs</h2>
                {blogs.map(m => 
                    <div className="each-blog-item">
                    <Link style={{ color : "#444" }} to={'/blogs/' + m.slug}><h2 className="blog-heading">{m.title}</h2></Link>
                    {/* <h6 className="blog-date">{m.published_on}</h6> */}
    
                    <div className="row m-0">
                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <img src={base + m.image} className="img blog-card-image img-fluid" alt=""/>
                        </div>
                        <div className="col-md-10 d-flex align-items-center">
                            <p className="blog-card-content"
                            dangerouslySetInnerHTML={{ __html: `${m.intro}` }}
                            ></p>
                        </div>
                    </div>
                    <Link to={'/blogs/'+m.slug}><h5 className="text-right read-more">Read More...</h5></Link>
                </div>    
                )}
            </div>
            </div>
        );
    }
}

export default BlogContainer;