import React, { Component } from 'react';

import axios, { base } from "../../axios-rsk";

import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css";
class TestimonialCarousel extends Component {

    state = {
        testimonials: [],
        loading: true

    }

    componentDidMount() {
        axios.get('/home/testimonial/')
            .then(res => {
                const fetchedTestimonials = res.data;
                this.setState({ loading: false, testimonials: fetchedTestimonials });
            })
            .catch(err => {
                this.setState({ loading: false })
            })
    }
    render() {
        return (
            <div className="testimonial_container container">
                <h1 className="testimonials_heading"> Testimonials</h1>
                <div style={{width:"80%", margin: "auto", boxShadow:"0px 0px 7px rgba(0, 0, 0, 0.2)", backgroundColor:"white"}}>
                    <Carousel 
                    showArrows={true} 
                    showThumbs={false} 
                    autoPlay={true} 
                    swipeable={true} 
                    infiniteLoop={true}
                    dynamicHeight={true}
                    emulateTouch={true}
                    interval={8000}
                    showStatus={false} >
                        {
                            this.state.testimonials.map(testimonial => (
                                <div className="testimonial-carousel">
                                    <div className="content">
                                        <img src={base + testimonial.image} className=" img-responsive" />
                                        <h1>
                                            {testimonial.name}
                                        </h1>
                                        <h2>{testimonial.designation}</h2>
                                        <p>{testimonial.message}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        )
    }
}

export default TestimonialCarousel;