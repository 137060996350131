import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import {base} from '../../axios-rsk'
export default class VennDiagram extends Component {
    render() {
        return (
            <div className="container d-flex justify-content-center homePage_figure_container">
                <img className="homePage_figure_image img img-fluid"  src={base + this.props.image}></img>
            </div>
        );
    };

}


