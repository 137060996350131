import Axios from 'axios';
import React, { Component } from 'react';
import PartnerPageCardComp from '../common/partnerPageCardTemplate';
import axios, { base } from '../axios-rsk';
class Partner extends Component {
    
    state = {
        partner : [],
        isLoading:true
    }

    componentDidMount = async() => {
        const {data:partner} = await axios.get('/partner/')
        this.setState({ partner, isLoading:false })
    }
    
    render() {
        let el = <div className="container d-flex vh-100 justify-content-center align-items-center pt-5"><h1 className="pt-5">Loading ...</h1></div>
        const partner = this.state.partner === undefined ? null : this.state.partner
        if (this.state.isLoading === false) {
            el = <div className="partner_cards_container container">
                <h1 className="p-2 mb-5 mt-5 text-center partner_page_main_heading"><span>Our Partners</span></h1>
            <div className="row d-flex justify-content-center">
                {partner.map(m => 
                    <div className="col-md-3 partner_page_each_card p-0">
                        <PartnerPageCardComp slugPrefix="partner" linkName="Learn More" borderRadius="0.5rem" title={m.title} url={m.slug} body={m.body} website={m.website} logo={m.logo}/>
                    </div>
                )}
            </div>
        </div>
        }
        return (
            el
        );
    }
}

export default Partner;